import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/service/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit {
  category: any;
  subCategory: any;
  products: any;
  selectedProduct: any;
  showProduct = false;
  searchText = '';

  isOpenFilter = false;
  constructor(private productService: ProductService,private route:ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.category = params['cat'];
      this.subCategory = params['sub-cat'];
    });
setTimeout(() => {

  this.getProducts();
}, 5000);
  }

  getProducts() {
    this.productService.getProduct().subscribe(
      (res) => {
        this.products = res;
        this.products.forEach((product) => {
          let priceRange = [];
          product.items.forEach((item) => {
            priceRange.push(item.price);
          });
          product['price'] = {
            from: Math.min.apply(null, priceRange),
            to: Math.max.apply(null, priceRange),
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // productDetail(id) {
  //   this.selectedProduct = this.products.filter((product) => product.id == id);
  //   this.showProduct = true;
  // }
  hideProduct(res) {
    if (res == 'false') {
      this.showProduct = false;
    }
  }
  sortProductList(val){
    if(val == 1){
      this.products = this.products.sort((n1,n2)=>{
        if(n1.price.from >n2.price.from) return 1;
        if(n1.price.from <n2.price.from) return -1;
        return 0;
      })
    }else if(val == 2){
      this.products = this.products.sort((n1,n2)=>{
        if(n1.price.from <n2.price.from) return 1;
        if(n1.price.from >n2.price.from) return -1;
        return 0;
      })
    }
  }
  openFilter(){
    this.isOpenFilter = !this.isOpenFilter
  }
  closeFilter(){
    this.isOpenFilter = false
  }

}
