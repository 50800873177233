import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';

@Component({
  selector: 'app-report-center',
  templateUrl: './report-center.component.html',
  styleUrls: ['./report-center.component.css']
})
export class ReportCenterComponent implements OnInit {
  reportType = ['Order Report','Cancel Report','Return Report','Payment Report','Tax Report']
  date = new Date();
  downloadForm = new FormGroup({
    format: new FormControl('excel' , { validators: [Validators.required] }),
    reportType: new FormControl(null, { validators: [Validators.required] }),
    start: new FormControl(this.date, { validators: [Validators.required] }),
    end: new FormControl(this.date, { validators: [Validators.required] })
  });
  reportList = []
  constructor(public datepipe: DatePipe,private excelService : ExcelGeneratorService) { }

  ngOnInit(): void {
  }

  downloadExcelFile(){
    if(this.downloadForm.valid){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `Report-`+latest_date;
    // let downloadList = this.orderList

    let start_date =this.datepipe.transform(this.downloadForm.value.start, 'yyyy-MM-dd')
    let end_date =this.datepipe.transform(this.downloadForm.value.end, 'yyyy-MM-dd')
    let downloadList = this.reportList.filter((order) => order.poDate >= start_date && order.poDate <= end_date)

    if(this.downloadForm.value.status !== "all"){
      downloadList = downloadList.filter((order) => order.status == this.downloadForm.value.status)
    }
    if(this.downloadForm.value.format == 'excel'){
      this.excelService.exportAsExcelFile(downloadList, fileName);
    }else if(this.downloadForm.value.format == 'pdf'){
      this.excelService.exportAsPdfFile(downloadList, fileName);
    }
  }
}

}
