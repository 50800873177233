import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute , Router  } from '@angular/router';
import { ProductService } from 'src/app/service/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/service/cart.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  // @Input() selectedProduct: any;
  @Output() showProduct = new EventEmitter<string>();
  productId: any;
  selectedProduct: any;
  products: any;
  category : any;
  subCategory : any;
  form: FormGroup;
  cart = [];
  myInterval = 3500;
  activeSlideIndex = 0;
  cartFormValid = true;
  zoomEnabled = false;
  constructor(
    private route: ActivatedRoute,
    private router : Router,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService,
    private cartService : CartService

  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => (this.productId = params['id'] , this.subCategory = params["sub-cat"], this.category = params["cat"]));
    console.log(this.productId);
    this.getProducts();

  }

  getProducts() {
    this.productService.getProduct().subscribe(
      (res) => {
        this.products = res;
        this.products.forEach((product) => {
          let priceRange = [];
          product.items.forEach((item) => {
            priceRange.push(item.price);
          });
          product['price'] = {
            from: Math.min.apply(null, priceRange),
            to: Math.max.apply(null, priceRange),
          };
          this.selectedProduct = this.products.filter(
            (product) => product.id == this.productId
          )[0];
          console.log(this.selectedProduct);
          let formGroup = {};
          this.selectedProduct.items.forEach((ele) => {
            formGroup[ele.size] = new FormControl(0, {
              validators: [Validators.max(ele.stock), Validators.min(0)],
            });
          });
          this.form = new FormGroup(formGroup);
          // console.log(this.form);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  addToCart(id) {
    // for (const iterator of this.form.value[0]) {
    //   console.log(iterator)
    // }
    this.cartFormValid = false
    for (const key in this.form.value) {
      if (Object.prototype.hasOwnProperty.call(this.form.value, key)) {
        if(this.form.value[key] > 0){
          this.cartFormValid = true
        }
      }
    }
    if(this.form.valid && !this.form.pristine && this.cartFormValid){


    console.log(this.form.value);
    let quantityList = [];
    for (const property in this.form.value) {
      quantityList.push({
        size: property,
        quantity: this.form.value[property],
      });
    }
    this.cart = [];
    if (sessionStorage.getItem('cart')) {
      this.cart = JSON.parse(sessionStorage.getItem('cart'));
    }
    if (this.cart.filter((cart) => cart.id == id)[0]) {
      let cartVal = this.cart.filter((cart) => cart.id == id)[0].quantity;
      let newCartVal = quantityList;
      cartVal.forEach((cart) => {
        quantityList.forEach((quan) => {
          if (cart['size'] == quan['size']) {
            cart['quantity'] += quan['quantity'];
          }
        });
      });
    } else {
      this.cart.push({ id: id, quantity: quantityList });
    this.cartService.cartCount(this.cart.length)

    }
    console.log(this.cart);
    sessionStorage.setItem('cart', JSON.stringify(this.cart));
    this.form.markAsPristine();
    this.form.reset();
    this.sharedService.getCartCount(this.cart.length)
    this.openSnackBar('Product has been added to cart successfully', 'View Cart')
  }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom' }).onAction()
      .subscribe(() => this.router.navigateByUrl('/cart'));

      ;

  }
  // backToProductList() {
  //   this.showProduct.emit('false');
  // }



  imageZoom(imgID, index,resultID) {
    imgID = imgID+index
    console.log('enter')
    this.zoomEnabled = true
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    lens.setAttribute("id", "img-zoom-lens");
    img.parentElement.insertBefore(lens, img);
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      e.preventDefault();
      pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }
  clearZoom(){
    this.zoomEnabled = false;
    document.getElementById("img-zoom-lens").remove();


  }
}
