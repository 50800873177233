<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">HISTORY</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">ORDERS</div>
      <div class="col-md-6">
        <div class="search-bar-cont">
          <div class="search-input">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" placeholder="Search" autocomplete="off">
          </div>
          <div class="search-cont" (click)="filterTable(searchText)"><img width="100%" src="/assets/icons/search-bar.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="px-g order-cont">
  <div class="sr-container px-0 ">
    <div class="col-12  mt-4 px-0">
      <div class="row justify-content-end br ds table-heading-cont align-items-center">
        <!-- <div class="col-auto">
          <button class="btn btn-sr-secondary">Filter</button>
        </div> -->
        <div class="col-auto pr-0 d-flex align-items-center" >
          <button class="btn btn-sr-icon d-flex align-items-end d-flex" (click)="downloadExcel(downloadExcelDialog)">
            <div class="mr-2">
              <img src="/assets/icons/download-icon.svg" alt="">
            </div>
            <div>
              Download
            </div>
          </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-sr-icon d-flex align-items-end d-flex"  [matMenuTriggerFor]="filter">
            <div class="mr-2">
              <img src="/assets/icons/filter-icon.svg" alt="">
            </div>
            <div>
              Filter
            </div>
          </button>
          <mat-menu #filter="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let sat of status" (click)="onFilter(sat)" >{{sat | titlecase}}</button>
          </mat-menu>
        </div>

    </div>
  </div>
    <div class="order-list-cont ">
      <table class="ds br" mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let col of displayedColumns">
          <ng-container matColumnDef="{{col}}" class="samle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}" [ngClass]="{'fix': col == 'status'}" >{{col}}</th>
            <td mat-cell *matCellDef="let orderList"
            [routerLink]="orderList['poNumber']"
            [ngClass]="{'completed': orderList.status == 'completed','revised': orderList.status == 'revised','shipped': orderList.status == 'shipped', 'approved': orderList.status == 'approved','pending': orderList.status == 'pending','rejected': orderList.status == 'rejected', 'fix': col == 'status'}"><div>{{orderList[col]}}</div></td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let orderList; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator class="mt-2 ds br" #paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
    </div>
  </div>

</div>
<ng-template #downloadExcelDialog>
  <h2 matDialogTitle>Download</h2>
  <mat-dialog-content>
    <form [formGroup]="downloadForm" >
    <mat-form-field appearance="fill">
      <mat-label>Format</mat-label>
      <mat-select  formControlName="format" name="format">
        <mat-option value="excel">EXCEL</mat-option>
        <mat-option value="pdf">PDF</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select  formControlName="status" name="status">
        <mat-option *ngFor="let sat of status" value="{{sat}}">{{sat | titlecase}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field color="accent"  appearance="fill">
      <mat-label>PO Date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="downloadForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="downloadForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
  </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button matDialogClose class="btn mb-3 btn-sr-primary" (click)="downloadExcelFile()">DOWNLOAD</button>
  </mat-dialog-actions>
</ng-template>
