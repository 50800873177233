<app-spinner *ngIf="spinner"></app-spinner>
<div class="success-cont-overlay" *ngIf="success">

  <div class="success-cont">
    <ng-lottie *ngIf="successSvg" height="220px" [options]="options"></ng-lottie>
    <ng-lottie *ngIf="loaderSvg" height="220px" [options]="optionsLoader"></ng-lottie>
    <div *ngIf="loaderSvg" class="success-text">
      Please wait
    </div>
    <div *ngIf="successSvg" class="success-text">
      {{successText}}
    </div>
  </div>
</div>

<!-- <ng-template #success>
  <mat-dialog-content>
    <div class="d-flex flex-column align-items-center">
      <ng-lottie *ngIf="successSvg" height="220px" [options]="options"></ng-lottie>
      <ng-lottie *ngIf="loaderSvg" height="220px" [options]="optionsLoader"></ng-lottie>
      <div *ngIf="loaderSvg" class="success-text">
        Please wait
      </div>
      <div *ngIf="successSvg" class="success-text">
        {{successText}}
      </div>
    </div>
  </mat-dialog-content>
</ng-template> -->
<!-- <app-loader *ngIf="loader"></app-loader> -->
<!-- <app-c-nav></app-c-nav> -->

<!-- <div class="router-cont"> -->

  <router-outlet></router-outlet>
<!-- </div> -->

