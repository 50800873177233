import { Component, OnInit } from '@angular/core';
/**Component which contains Admins sub components */
@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {
/**Variable to handle Open and close for Menu bar */
  menuOpened = false;
  ngOnInit(): void {
  }
  /**
   * Function to Emit value when menu is clicked
   * @param menuOpened {Boolean}
   */
  send(menuOpened){
    this.menuOpened = menuOpened;
  }

}
