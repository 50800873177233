import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { DatePipe} from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { DragDropModule } from '@angular/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './customer/login/login.component';
import { SignupComponent } from './customer/signup/signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { LoaderComponent } from './common/loader/loader.component';
import { ProductListComponent } from './customer/product-list/product-list.component';
import { ProductComponent } from './customer/product/product.component';
import { CNavComponent } from './customer/c-nav/c-nav.component';
import { HttpClientModule } from '@angular/common/http';
import { CartComponent } from './customer/cart/cart.component';
import { ProfileComponent } from './customer/profile/profile.component';
import { AddProductComponent } from './admin/add-product/add-product.component';
import { HomeComponent } from './customer/home/home.component';
import { SubCatComponent } from './customer/sub-cat/sub-cat.component';
import { OrderListComponent } from './customer/order-list/order-list.component';
import { OrderComponent } from './customer/order/order.component';
import { ExcelGeneratorService } from './service/excel-generator.service';

import { AngularTiltModule } from 'angular-tilt';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import { ChartsModule } from 'ng2-charts';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatRippleModule} from '@angular/material/core';
import { AdminOrderListComponent } from './admin/admin-order-list/admin-order-list.component';
import { AdminOrderComponent } from './admin/admin-order/admin-order.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ReportSectionComponent } from './admin/report-section/report-section.component';
import { ANavComponent } from './admin/a-nav/a-nav.component';
import { AdminPaymentListComponent } from './admin/admin-payment-list/admin-payment-list.component';
import { ReportCenterComponent } from './admin/report-center/report-center.component';
import { ReturnRequestComponent } from './admin/return-request/return-request.component';
import { CustomerListComponent } from './admin/customer-list/customer-list.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { ReturnRequestListComponent } from './admin/return-request-list/return-request-list.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AtNavComponent } from './admin/at-nav/at-nav.component';
import { LandingPageComponent } from './customer/landing-page/landing-page.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { RouterModule } from '@angular/router';

import { InventoryComponent } from './admin/inventory/inventory.component';
import { LottieModule } from 'ngx-lottie';
import { ImgMagnifier } from 'ng-img-magnifier';
import { NgxImgZoomModule  } from 'ngx-img-zoom';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ProductFilterComponent } from './customer/product-filter/product-filter.component';
import { ShimmerComponent } from './common/shimmer/shimmer.component';
export function playerFactory() {
  return import('lottie-web');
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    SpinnerComponent,
    LoaderComponent,
    ProductListComponent,
    ProductComponent,
    CNavComponent,
    CartComponent,
    ProfileComponent,
    AddProductComponent,
    HomeComponent,
    SubCatComponent,
    OrderListComponent,
    OrderComponent,
    AdminOrderListComponent,
    AdminOrderComponent,
    DashboardComponent,
    ReportSectionComponent,
    ANavComponent,
    AdminPaymentListComponent,
    ReportCenterComponent,
    ReturnRequestComponent,
    CustomerListComponent,
    CustomerComponent,
    ReturnRequestListComponent,
    AtNavComponent,
    LandingPageComponent,
    AdminPanelComponent,
    InventoryComponent,
    ProductFilterComponent,
    ShimmerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    MatRadioModule,
    AngularTiltModule,
    CarouselModule.forRoot(),
    MatSnackBarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,MatMenuModule,MatRippleModule,MatTabsModule,MatDialogModule,ChartsModule,
    LottieModule.forRoot({ player: playerFactory}),
    ImgMagnifier,
    NgxImgZoomModule,
    NgxSliderModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  providers: [DatePipe, ExcelGeneratorService],
  bootstrap: [AppComponent],
})
export class AppModule {}
