
<div class="product-filter-cont col">
  <div class="row mx-0 mt-4 justify-content-between align-items-center">
    <div class="heading">
      Filter
    </div>
    <div class="back-icon" (click)="closeFilter()">
      <img src="/assets/icons/arrow-left-white-bg.svg" alt="">
    </div>
  </div>

  <hr>
  <div class="filter-cont">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Price range
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Color
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>I'm visible because I am open</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Size
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="toppings" class="d-flex flex-column">
          <mat-checkbox formControlName="pepperoni">S</mat-checkbox>
          <mat-checkbox formControlName="extracheese">M</mat-checkbox>
          <mat-checkbox formControlName="mushroom">XL</mat-checkbox>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Material
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="toppings" class="d-flex flex-column">
          <mat-checkbox formControlName="pepperoni">Cotton</mat-checkbox>
          <mat-checkbox formControlName="extracheese">Nylon</mat-checkbox>
          <mat-checkbox formControlName="mushroom">Fabric</mat-checkbox>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="btn-cont row w-100 justify-content-center">
    <div>
      <button class="btn btn-sr-secondary mr-4">Clear</button>
    </div>
    <div>
      <button class="btn btn-sr-primary">Apply</button>
    </div>
  </div>
</div>
