<div class="container-fluid mx-auto main-cont">
  <div class="container login-comp py-3 px-0 d-flex justify-content-center align-items-center">
    <div class="row h-100 w-100 justify-content-center align-items-center">
      <div class="col-md-6 show-lg">
        <img width="85%" src="/assets/icons/launch-icon.svg" alt="">
      </div>
      <div class="col-md-6 px-0">
        <div class="login-cont position-relative">
          <div class="login-container" [ngClass]="{'inactive' : isLoggedIn}">
            <div class="section-heading mb-3">LOGIN</div>
            <form action="">
              <mat-form-field class="contact-full-width" appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput type="email">
              </mat-form-field>
              <mat-form-field class="contact-full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password">
              </mat-form-field>
            </form>
            <div class="forgot-pass mt-3 mb-2"><a [routerLink]="['/signup']"  class="btn-sr-link">Forgot Password?</a></div>
            <div class="signup">Don't have an account? <a [routerLink]="['/signup']" class="btn-sr-link">Sign up</a></div>
            <button class="btn btn-sr-primary mt-4" (click)="onLogin()">
              <div>Login</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>
          <div class="otp-container" [ngClass]="{'active' : isLoggedIn}">
            <div class="section-heading mb-3">OTP Verification</div>
            <div class="mb-3 o8">Enter the OTP you recevied</div>
            <div>
              <div class="userInput mb-3">
                <input class="opt-input" type="password" id='ist' maxlength="1" (keyup)="clickEvent('ist','sec')">
                <input class="opt-input" type="password" id="sec" maxlength="1" (keyup)="clickEvent('sec','third')">
                <input class="opt-input" type="password" id="third" maxlength="1" (keyup)="clickEvent('third','fourth')">
                <input class="opt-input" type="password" id="fourth" maxlength="1" (keyup)="clickEvent('fourth','fifth')">
                <input class="opt-input" type="password" id="fifth" maxlength="1">
              </div>
            </div>
            <div class="forgot-pass mt-1 mb-2"><a [routerLink]="['/signup']" class="btn-sr-link">Resend OTP</a></div>
            <button class="btn btn-sr-primary mt-4" (click)="onLogin()" [routerLink]="['/']">
              <div>Continue</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
