import { Component, OnInit } from '@angular/core';
import 'vanilla-tilt';

declare var VanillaTilt;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myInterval = 3500;
  activeSlideIndex = 0;
  banner : any[];
  constructor() { }

  ngOnInit(): void {
    this.banner = [{"imagePath":'/assets/images/banner1.png'},{"imagePath":'/assets/images/banner1.png'},{"imagePath":'/assets/images/banner1.png'}]
    VanillaTilt.init(document.querySelectorAll(".cat-cont"), { max: 3, speed: 800 });
    VanillaTilt.init(document.querySelectorAll(".cat-cont"));
  }

}
