<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">PRODUCTS</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">{{category}}</div>
      <div class="col-md-6">
        <div class="search-bar-cont">
          <div class="search-input">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" placeholder="Search product"
              autocomplete="off">
          </div>
          <div class="search-cont"><img width="100%" src="/assets/icons/search-bar.svg"></div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="pb-5 pt-3 px-g  pro-list-cont">
  <div class="sr-container">
    <div class="row">
      <!-- <div class="col-md-3">
<div class="filter-cont">
  <div>Filters</div>
</div>

      </div>-->
      <div class="col-12  mb-3">
        <div class="row  justify-content-center justify-content-sm-end br ds product-filter-cont align-items-center">
          <div class="col-auto pr-0">
            <button (click)="openFilter()" class="btn btn-sr-icon d-flex align-items-center">
              <div class="mr-2">
                <img src="/assets/icons/filter-icon.svg" alt="">
              </div>
              <div>
                Filter
              </div>
            </button>
          </div>
          <div class="col-auto sort-input">
            <mat-form-field appearance="fill">
              <div class="sort-icon-cont"><img src="/assets/icons/sort-icon.svg" alt=""></div>
              <mat-select (selectionChange)="sortProductList($event.value)" [value]="0">
                <mat-option [value]="0">Newest First</mat-option>
                <mat-option [value]="1">Price Low to High</mat-option>
                <mat-option [value]="2">Price High to Low</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
      <div class="row">

      </div>
    </div> -->
      <!-- <div class="filter-cont">
        <div class="filter-title mb-3">Filters</div>
        <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>
      </div> -->
      <div class="col product-list-cont br">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 " *ngIf="products ;else shimmer">
          <div class="col px-0 d-flex align-items-stretch" *ngFor="let product of products | filter:searchText">
            <div class="product-cont" routerLink="/product/{{category}}/{{subCategory}}/{{product.id}}">
              <div class="product-img">
                <img width="100%" [src]="product.imgPath[0]" alt="{{product.name}}">
              </div>
              <div class="content-cont">
                <div class="product-name">{{product.name}}</div>
                <div class="product-price">₹ {{product.price.from}} - {{product.price.to}}</div>
                <div class="product-cart-btn">
                  <button class="btn add-to-cart-btn" routerLink="/product/boys/t-shirt/{{product.id}}">VIEW
                    PRODUCT</button>
                </div>
              </div>
              <hr class="pro-list-hr">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="filter" [ngClass]="{'active':isOpenFilter}">
  <app-product-filter (isCloseFilter)="closeFilter()"></app-product-filter>
</div>
<!-- <div class="hide-product" [ngClass]="{'show-product' : showProduct}"> -->
<!-- <app-product (showProduct)="hideProduct($event)" [selectedProduct]="selectedProduct[0]" *ngIf="showProduct"></app-product> -->
<!-- </div> -->

<ng-template #shimmer>
  <app-shimmer [shimmer]="'productList'"></app-shimmer>
</ng-template>

