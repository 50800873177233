<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">LIST</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">RETURN REQ</div>
      <div class="col-md-6">
        <div class="search-bar-cont">
          <div class="search-input">
            <input class="form-control" type="text" name="searchText" [(ngModel)]="search" placeholder="Search product" autocomplete="off">
          </div>
          <div class="search-cont" (click)="filterTable(search)"><img width="100%" src="/assets/icons/search-bar.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
