import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-request-list',
  templateUrl: './return-request-list.component.html',
  styleUrls: ['./return-request-list.component.css']
})
export class ReturnRequestListComponent implements OnInit {
  searchText = '';
  search = '';
  constructor() { }

  ngOnInit(): void {
  }



  filterTable(value){
    this.searchText = value
  }
}
