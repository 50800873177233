import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  isLoggedIn = false;
  constructor() { }

  ngOnInit(): void {
  }
  onsignup(){
    this.isLoggedIn = true
  }
}
