<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">HISTORY</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">PAYMENTS</div>
      <div class="col-md-6">
        <div class="search-bar-cont">
          <div class="search-input">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" placeholder="Search" autocomplete="off">
          </div>
          <div class="search-cont" (click)="filterTable(searchText)"><img width="100%" src="/assets/icons/search-bar.svg"></div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="px-g order-cont">
  <div class="sr-container px-0 ">
    <div class="col-12 mt-4 px-0">
      <div class="row br justify-content-end table-heading-cont align-items-center ds">
        <div class="col-auto pr-0 d-flex align-items-center " >
          <button class="btn btn-sr-icon d-flex align-items-end d-flex mr-2" (click)="downloadExcel(downloadExcelDialog)">
            <div class="mr-2">
              <img src="/assets/icons/download-icon.svg" alt="">
            </div>
            <div>
              Download
            </div>
          </button>
        </div>
    </div>
  </div>
    <div class="order-list-cont">
      <div class="table-scroll ds br">
      <table mat-table [dataSource]="dataSource" matSort class="ds">
        <ng-container *ngFor="let col of displayedColumns ">
          <ng-container matColumnDef="{{col}}" class="samle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}" [ngClass]="{'fix': col == 'status'}" >{{col}}</th>
            <td mat-cell *matCellDef="let orderList" ><div>{{orderList[col]}}</div></td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let orderList; columns: displayedColumns;"></tr>
      </table>
    </div>
      <mat-paginator class="mt-2 ds br" #paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
    </div>
    <ng-template #downloadExcelDialog>
      <h2 matDialogTitle>Download</h2>
      <mat-dialog-content>
        <form [formGroup]="downloadForm" >
        <mat-form-field appearance="fill">
          <mat-label>Format</mat-label>
          <mat-select  formControlName="format" name="orderSummaryDownload" value="0">
            <mat-option value="excel">EXCEL</mat-option>
            <mat-option value="pdf">PDF</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="accent"  appearance="fill">
          <mat-label>Payment date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="downloadForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="downloadForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </form>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button matDialogClose class="btn mb-3 btn-sr-primary" (click)="downloadExcelFile()">DOWNLOAD</button>
      </mat-dialog-actions>
    </ng-template>
  </div>

</div>



