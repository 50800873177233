import { EventEmitter, Output, Component, OnInit } from '@angular/core';
/** Admin Top Nav bar Component */
@Component({
  selector: 'app-at-nav',
  templateUrl: './at-nav.component.html',
  styleUrls: ['./at-nav.component.css']
})
export class AtNavComponent implements OnInit {
  /**Emitter to emit when Menu is clicked */
  @Output() emitter:EventEmitter<boolean> = new EventEmitter<boolean>();
  /**Variable to handle Open and close for Menu bar */
  menuOpened = false
  ngOnInit(): void {
  }
  /**
   * Function to Emit value when menu is clicked
   */
  menu(){
    this.menuOpened = !this.menuOpened
    this.emitter.emit(this.menuOpened);
  }
}
