<div class="mb-5">
  <div class="sr-container">
    <div class="row">
      <div class="col-12 my-5">
        <div class="section-heading heading text-center">category</div>
        <hr class="sr-hr">
      </div>
    </div>
<ng-container *ngIf="subCatList ;else noCategory">


    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4" >
      <ng-container *ngFor="let subCat of subCatList.subcatergory">

      <div class="col" >
        <div class="sub-cat-cont" aTilt [tiltSettings]="tiltSettings" routerLink="/product/{{category}}/{{subCat.path}}">
        <!-- <div class="sub-cat-cont" data-tilt data-tilt-glare data-tilt-max-glare="0.3" data-tilt-scale="1.05" routerLink="/{{category}}/{{subCat.path}}"> -->
          <div class="d-flex flex-wrap">
            <div class="col-6">
              <div class="sub-cat-text">{{subCat.name}}</div>
              <div><hr class="sub-cat-hr"></div>
            </div>
            <div class="col-6 d-flex align-items-end">
              <div class="sub-cat-img"><img width="100%" [src]="subCat.imgPath"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    </div>
  </ng-container>
    <ng-template #noCategory>
      <div class="row">
        <div class="col-md-3" *ngFor="let i of [].constructor(4)">
          <div class="shimmer-cont">
            <div class="shimmerBG media"></div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>
