<div class="container-fluid">
  <div class="container">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 section-sub-heading mb-2">Product Info</div>
        <div class="col-12">
          <div class="col-md-12">
            <div class="row">
              <div cdkDropList class="example-list col-md-2" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let url of imageurls;  let i = index" cdkDrag>
                  <img class="img-fluid" [src]="url.base64String">
                <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
                </div>
              </div>
              <!-- <div class="col-md-2 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                <img class="img-fluid" [src]="url.base64String">
                <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
              </div> -->
            </div>
          </div>
          <input type="file"
                 class="form-control"
                 (change)="onSelectFile($event)"
                 multiple accept="image/*" />
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>Product Name</mat-label>
            <input formControlName='name' matInput type="text">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>Category</mat-label>
            <input formControlName='category' matInput type="text">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>Sub Category</mat-label>
            <input formControlName='subcategory' matInput type="text">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>discount</mat-label>
            <input formControlName='discount' matInput type="text">
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="col-12 section-sub-heading mt-3 mb-2">Items</div>
          <div class="col-12" *ngFor="let item of tItems.controls; let i = index">
            <div class="row" [formGroup]="item">
              <div class="col-md-4">
                <mat-form-field class="contact-full-width" appearance="fill">
                  <mat-label>Size</mat-label>
                  <input matInput type="text" formControlName='size'>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="contact-full-width" appearance="fill">
                  <mat-label>Price</mat-label>
                  <input matInput type="number" formControlName='price'>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="contact-full-width" appearance="fill">
                  <mat-label>Stock</mat-label>
                  <input matInput type="number" formControlName='stock'>
                </mat-form-field>
              </div>
              <div class="col-md-3" *ngIf="i !== 0">
                <button (click)="deleteItems(i)">delete</button>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" (click)="addItems()">Add Items</button>
        </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12">Product Description</div>
              <div class="col-12" *ngFor="let desc of tDesc.controls; let i = index">
                <div class="row" [formGroup]="desc">
                  <div class="col-md-9">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Descriptions</mat-label>
                      <input matInput type="text" formControlName='desc'>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3" *ngIf="i !== 0">
                    <button class="btn btn-danger" (click)="deleteDescription(i)">Delete</button>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-primary" (click)="addDescription()">Add Description</button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12">Product Specification</div>
              <div class="col-12" *ngFor="let spec of tSpec.controls; let i = index">
                <div class="row" [formGroup]="spec">
                  <div class="col-md-4">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Title</mat-label>
                      <input matInput type="text" formControlName='title'>
                    </mat-form-field>
                  </div>
                  <div class="col-md-5">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Value</mat-label>
                      <input matInput type="text" formControlName='value'>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3" *ngIf="i !== 0">
                    <button class="btn btn-danger" (click)="deleteSpecificaton(i)">Delete</button>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-primary" (click)="addSpecification()">Add Specification</button>
              </div>
            </div>
          </div>

          <div class="col-12"><button class="btn btn-primary" (click)="addProduct()">Add Product</button></div>


        </div>

    </form>

  </div>
</div>
