<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">CUSTOMER</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">CUSTOMER NAME</div>
    </div>
  </div>
</div>
<div class="px-g profile-cont-bg">
  <div class="sr-container px-0">
    <div class="py-5" *ngIf="profile">

      <mat-tab-group mat-align-tabs="start">
        <mat-tab>
          <ng-template mat-tab-label>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
            </svg>
            PERSONAL INFORMATION
          </ng-template>
          <div class="col p-4">
            <div class="row personal-info-cont mx-auto">
              <div class="col-12 word-break">
                <div class="row">
                  <div class="col-5 pd-title">Name</div>
                  <div class="col-7 text-right">{{profile.fname}} {{profile.lname}}</div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-5 pd-title">Mobile</div>
                  <div class="col-7 text-right">{{profile.mobile}}</div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-5 pd-title">E Mail</div>
                  <div class="col-7 text-right">{{profile.email}}</div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-5 pd-title">Shop Name</div>
                  <div class="col-7 text-right">{{profile.shopname}}</div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-5 pd-title">GSTIN</div>
                  <div class="col-7 text-right">{{profile.gstin}}</div>
                </div>
              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab label="DELIVERY LOCATIONS">
          <ng-template mat-tab-label>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
            </svg>
            DELIVERY LOCATIONS
          </ng-template>
          <div class="col-12 p-4">
            <!-- <div class="section-sub-heading mb-4 bold">DELIVERY ADDRESS</div> -->
            <div class="row">

              <div class="col-md-3 mb-3 position-relative" *ngFor="let address of profile.address">
                <div class="delivery-address-cont">
                  <div class="delivery-icon mb-3"><img width="50px" src="/assets/icons/store.svg"></div>
                  <div>{{address.line1}}</div>
                  <div>{{address.line2}}</div>
                  <div>{{address.city}}</div>
                  <div>{{address.state}}</div>
                  <div>{{address.zip}}</div>
                  <hr class="delivery-hr">
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-end"><button class="btn btn-sr-secondary mt-3"
               (click)="newAddress()">Add new address</button></div> -->
          </div>

        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z" />
            </svg>
            BANK DETAILS
          </ng-template>
          <div class="col-12 word-break p-4">
            <div class="col-12 personal-info-cont mx-auto" *ngIf="profile.bankDetails.available ; else noBankDetails">
              <div class="row">
                <div class="col-5 pd-title">Bank Name</div>
                <div class="col-7 text-right">{{profile.bankDetails.bankName}}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-5 pd-title">Account Number</div>
                <div class="col-7 text-right">{{profile.bankDetails.accNo}}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-5 pd-title">IFSC Code</div>
                <div class="col-7 text-right">{{profile.bankDetails.ifscCode}}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-5 pd-title">Branch Name</div>
                <div class="col-7 text-right">{{profile.bankDetails.branchName}}</div>
              </div>
              <hr>
              <div class="row">
                <div class="col-5 pd-title">Account Type</div>
                <div class="col-7 text-right">{{profile.bankDetails.accType}}</div>
              </div>
            </div>

          </div>

        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
              width="24px" fill="#FFFFFF">
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <path
                    d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z" />
                  <rect height="2" width="6" x="9" y="12" />
                </g>
              </g>
            </svg>
            ORDER HISTORY
          </ng-template>
          <div class="col-12 px-0 pt-4">
            <div class="col-12  mb-3 px-4">
              <div class="row justify-content-end table-heading-cont align-items-center">
                <div class="col-auto pr-0 d-flex align-items-center">
                  <button class="btn download-btn d-flex align-items-end d-flex"
                    (click)="downloadExcel(downloadExcelDialog)">
                    <div class="mr-2">DOWNLOAD</div>
                    <div class="dowload-btn-img">
                      <img width="20px" src="/assets/icons/file_download_black.svg">
                    </div>
                  </button>
                </div>
                <div class="col-auto">
                  <button mat-icon-button [matMenuTriggerFor]="filter">
                    <i class="material-icons">filter_list</i>

                  </button>
                  <mat-menu #filter="matMenu" xPosition="before">
                    <button mat-menu-item *ngFor="let sat of status" (click)="onFilter(sat)">{{sat |
                      titlecase}}</button>
                  </mat-menu>
                </div>

              </div>
            </div>
            <div class="order-list-cont">
              <table mat-table [dataSource]="dataSourceOrder" matSort #sort="matSort">
                <ng-container *ngFor="let col of displayedColumnsOrder">
                  <ng-container matColumnDef="{{col}}" class="samle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}"
                      [ngClass]="{'fix': col == 'status'}">{{col}}</th>
                    <td mat-cell *matCellDef="let orderList" [routerLink]="'/admin/orders/'+orderList['poNumber']"
                      [ngClass]="{'completed': orderList.status == 'completed','revised': orderList.status == 'revised','shipped': orderList.status == 'shipped', 'approved': orderList.status == 'approved','pending': orderList.status == 'pending','rejected': orderList.status == 'rejected', 'fix': col == 'status'}">
                      <div>{{orderList[col]}}</div>
                    </td>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsOrder"></tr>
                <tr mat-row *matRowDef="let orderList; columns: displayedColumnsOrder;"></tr>
              </table>
              <mat-paginator class="mt-2" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
              </mat-paginator>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M21 7.28V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-2.28c.59-.35 1-.98 1-1.72V9c0-.74-.41-1.37-1-1.72zM20 9v6h-7V9h7zM5 19V5h14v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2H5z" />
              <circle cx="16" cy="12" r="1.5" />
            </svg>
            PAYMENT HISTORY
          </ng-template>
          <div class="col-12 px-0">
            <div class="payment-list-cont">
              <div class="order-list-cont">
                <div class="table-scroll">
                  <table mat-table [dataSource]="dataSourcePayment" matSort #sortPayment="matSort">
                    <ng-container *ngFor="let col of displayedColumnsPayment ">
                      <ng-container matColumnDef="{{col}}" class="samle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col}}"
                          [ngClass]="{'fix': col == 'status'}">{{col}}</th>
                        <td mat-cell *matCellDef="let paymentList">
                          <div>{{paymentList[col]}}</div>
                        </td>
                      </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPayment"></tr>
                    <tr mat-row *matRowDef="let paymentList; columns: displayedColumnsPayment;"></tr>
                  </table>
                </div>
                <mat-paginator class="mt-2" #paginatorPayment [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
                </mat-paginator>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>
</div>

<ng-template #noBankDetails>
  <div class="col-12">
    Customer has not shared the Bank details. Please contact him through mobile or email for more info.
  </div>
</ng-template>

<ng-template #downloadExcelDialog>
  <h2 matDialogTitle>Download</h2>
  <mat-dialog-content>
    <form [formGroup]="downloadForm">
      <mat-form-field appearance="fill">
        <mat-label>Format</mat-label>
        <mat-select formControlName="format" name="format">
          <mat-option value="excel">EXCEL</mat-option>
          <mat-option value="pdf">PDF</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" name="status">
          <mat-option *ngFor="let sat of status" value="{{sat}}">{{sat | titlecase}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="accent"  appearance="fill">
        <mat-label>PO Date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="downloadForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="downloadForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button matDialogClose class="btn mb-3 btn-sr-primary" (click)="downloadExcelFile()">DOWNLOAD</button>
  </mat-dialog-actions>
</ng-template>
