import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  customerList :any;
  customerListRes :any;
  newCustomerList :any;
  newCustomerListRes :any;
  activePageDataChunk: any = [];
  activeAllPageDataChunk: any = [];
  pageSize = 5;
  search = '';
  searchText = '';
  constructor(private customerService : CustomerService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.customerService.getCustomerList().subscribe(res=>{
      this.customerList = res
      this.customerListRes = res
      this.newCustomerListRes = this.customerList.filter(customer => customer.status == 'pending')
      this.newCustomerList = this.newCustomerListRes
      this.activePageDataChunk = this.newCustomerList.slice(0,this.pageSize);
      this.activeAllPageDataChunk = this.customerList.slice(0,this.pageSize);
    })
  }
  onPendingPageChanged(e){
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.newCustomerList.slice(firstCut, secondCut);
  }
  onAllPageChanged(e){
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activeAllPageDataChunk = this.customerList.slice(firstCut, secondCut);
  }
  filterTable(value){
    this.newCustomerList = this.newCustomerListRes.filter(object => {
      return JSON.stringify(object)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    this.customerList = this.customerListRes.filter(object => {
      return JSON.stringify(object)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    this.activePageDataChunk = this.newCustomerList.slice(0,this.pageSize);
    this.activeAllPageDataChunk = this.customerList.slice(0,this.pageSize);
  }
}
