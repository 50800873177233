<div class="container-fluid mx-auto main-cont px-g">
  <div class="signup-comp sr-container py-3 px-0 d-flex justify-content-center align-items-center">
    <div class="row h-100 w-100 justify-content-center align-items-center">
      <div class="col-md-4 show-lg">
        <img width="85%" src="/assets/icons/launch-icon.svg" alt="">
      </div>
      <div class="col-md-8 px-0">
        <div class="signup-cont position-relative ds">
          <div class="signup-container" [ngClass]="{'inactive' : isLoggedIn}">
            <div class="section-heading mb-2">SIGN UP</div>
            <div class="section-sub-text mb-3 o8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, harum!
              Doloribus nam expedita voluptates sit.</div>
            <form action="">
              <div class="row">
                <div class="col-12 section-sub-heading mb-2">Personal Info</div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>E Mail</mat-label>
                    <input matInput type="email">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-12 section-sub-heading mt-3 mb-2">Shop Info</div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>Shop Name</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>GSTIN Number</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-12 section-sub-heading mt-3 mb-2">Address</div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>Address Line 1</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>Address Line 2</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>State</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="outline">
                    <mat-label>ZIP Code</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
              </div>

            </form>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div>
                <div class="signup">Already have an account? <a [routerLink]="['/login']"  class="btn-sr-link">Login</a></div>
              </div>
              <div>
                <button class="btn btn-sr-primary" (click)="onsignup()">
                  <div>signup</div>
                  <div class="pl-3 d-flex icon"><img src="./../../../assets/icons/arrow-white-right.svg"></div>
                </button>
              </div>
            </div>
          </div>
          <div class="otp-container" [ngClass]="{'active' : isLoggedIn}">
            <div class="section-heading mb-3">Under Verification</div>
            <div class="mb-3">Your account is under verification. You will be notified in Email once the account has been verified. Please be patience.</div>
            <div>
              <img class="verification-icon" src="./../../../assets/icons/verification.svg" alt="">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
