import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddProductComponent } from './admin/add-product/add-product.component';
import { CartComponent } from './customer/cart/cart.component';
import { HomeComponent } from './customer/home/home.component';
import { LoginComponent } from './customer/login/login.component';
import { OrderListComponent } from './customer/order-list/order-list.component';
import { ProductListComponent } from './customer/product-list/product-list.component';
import { ProductComponent } from './customer/product/product.component';
import { ProfileComponent } from './customer/profile/profile.component';
import { SignupComponent } from './customer/signup/signup.component';
import { SubCatComponent } from './customer/sub-cat/sub-cat.component';
import { PreloadAllModules } from '@angular/router';
import { OrderComponent } from './customer/order/order.component';
import { AdminOrderComponent } from './admin/admin-order/admin-order.component';
import { AdminOrderListComponent } from './admin/admin-order-list/admin-order-list.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { CustomerListComponent } from './admin/customer-list/customer-list.component';
import { ReportCenterComponent } from './admin/report-center/report-center.component';
import { AdminPaymentListComponent } from './admin/admin-payment-list/admin-payment-list.component';
import { ReturnRequestListComponent } from './admin/return-request-list/return-request-list.component';
import { LandingPageComponent } from './customer/landing-page/landing-page.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InventoryComponent } from './admin/inventory/inventory.component';


const routes: Routes = [
  {path: '',component: LandingPageComponent ,children:[
    {path: '',component: HomeComponent},
    {path: 'profile',component: ProfileComponent},
    {path: 'cart',component: CartComponent},
    {path: 'orders',component: OrderListComponent},
    {path: 'orders/:id',component: OrderComponent},
    {path: 'product/:cat',component: SubCatComponent},
    {path: 'product/:cat/:sub-cat',component: ProductListComponent},
    {path: 'product/:cat/:sub-cat/:id',component: ProductComponent},
  ]},
  {path: 'login',component: LoginComponent},
  {path: 'signup',component: SignupComponent},

  {path: 'admin',component:AdminPanelComponent ,
  children:[
    {path: '',component: DashboardComponent},
    {path: 'orders',component: AdminOrderListComponent},
    {path: 'orders/:id',component: AdminOrderComponent},
    {path: 'customer',component: CustomerListComponent},
    {path: 'customer/:id',component: CustomerComponent},
    {path: 'report-center',component: ReportCenterComponent},
    {path: 'payment',component: AdminPaymentListComponent},
    {path: 'return-request',component:  ReturnRequestListComponent},
    {path: 'inventory',component:  InventoryComponent},
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled',
    // useHash: true,
    preloadingStrategy: PreloadAllModules

  })],
  // providers:[ {provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
