import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelGeneratorService {
  constructor() {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = {
      Sheets: { data: myworksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(myworkbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  public exportAsPdfFile(json: any[], pdfFileName: string) {
    let concatArray = [];
    json.forEach((element) => {
      concatArray = [...concatArray, ...Object.keys(element)];
    });
    var array_union = [...new Set(concatArray)];

    let rows = [];
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);
    var col = [];
    col.push(array_union);
    json.forEach((row) => {
      let temp = []
      array_union.forEach(col => {
        if(row[col]){
          temp.push(row[col]);
        }else{
          temp.push("")
        }
      });
      rows.push(temp)
    });
    autoTable(doc, {
      head: col,
      body: rows,
      didDrawCell: (rows) => {},
    });
    doc.save(pdfFileName + '.pdf');
  }
}
