import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductService } from 'src/app/service/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css'],
})
export class AddProductComponent implements OnInit {
  imageDeleteFrom: FormGroup;
  imageurls =[];
  base64String: string;
  name: string;
  imagePath: string;

  imageList : any = [];

  form: FormGroup;
  constructor(private formBuilder: FormBuilder, private productService: ProductService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      category: new FormControl(null, { validators: [Validators.required] }),
      subcategory: new FormControl(null, { validators: [Validators.required] }),
      discount: new FormControl(null, { validators: [Validators.required] }),
      items: new FormArray([]),
      description: new FormArray([]),
      specification: new FormArray([]),
    });
    this.addItems()
    this.addDescription()
    this.addSpecification()
  }

  addProduct() {
    console.log(this.form.value)
    this.productService.addProduct(this.form.value, this.imageList)
  }

  addItems() {
    this.tItems.push(
      this.formBuilder.group({
        size: ['', Validators.required],
        price: ['', [Validators.required]],
        stock: ['', [Validators.required]],
      })
    );
  }
  addDescription() {
    this.tDesc.push(
      this.formBuilder.group({
        desc: ['', Validators.required],
      })
    );
  }
  addSpecification() {
    this.tSpec.push(
      this.formBuilder.group({
        title: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }
  deleteDescription(i){
    this.tDesc.removeAt(i);
  }
  deleteItems(i){
    this.tItems.removeAt(i);
  }
  deleteSpecificaton(i){
    this.tSpec.removeAt(i);
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
    console.log(this.imageDeleteFrom)
  }

  removeImage(i) {
    this.imageurls.splice(i,1);
    this.imageList.splice(i,1)
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (const iterator of event.target.files) {
        this.imageList.push(iterator)
      }
      console.log(this.imageList)
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageurls.push({ base64String: event.target.result, });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imageList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.imageurls, event.previousIndex, event.currentIndex);
    console.log(this.imageList)
  }

  get f() {
    return this.form.controls;
  }
  get tItems() {
    return this.f.items as FormArray;
  }
  get tDesc() {
    return this.f.description as FormArray;
  }
  get tSpec() {
    return this.f.specification as FormArray;
  }
}
