import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import { DatePipe } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
// import { StepperOrientation } from '@angular/material/stepper';
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';
import { MatStepper } from '@angular/material/stepper';
import {
  FormControl,
  FormGroup,
  FormArray,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import animNoPayment from 'src/assets/lottie/no-payment.json';
import { AnimationOptions } from 'ngx-lottie';
const pathNoPayment = `data:text/json;base64,${btoa(
  JSON.stringify(animNoPayment)
)}`;

@Component({
  selector: 'app-admin-order',
  templateUrl: './admin-order.component.html',
  styleUrls: ['./admin-order.component.css'],
})
export class AdminOrderComponent implements OnInit {
  orderList: any;
  date = new Date();
  orderSummaryDownload = 'excel';
  onEdit = false;
  cart = [];
  cartSession = [];
  cartForm: FormGroup;
  optionsNoPayment: AnimationOptions = {
    path: pathNoPayment,
    loop: true,
  };
  @ViewChild('pdfTable', { static: false }) pdfTable!: ElementRef;
  @ViewChild('stepper') private myStepper:  MatStepper;
  stepperOrientation: Observable<string>;

  constructor(
    public datepipe: DatePipe,
    private orderService: OrderService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private excelService: ExcelGeneratorService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    this.orderService.getOrder().subscribe((res) => {
      this.orderList = res[0];
      console.log(this.orderList);
      this.createForm();
    });
  }

  orderSummaryExcel() {
    let latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName = `orderSummary-` + latest_date + `.xlsx`;
    let json = [];
    let copyOrder = this.orderList;
    copyOrder.items.forEach((item) => {
      item.quantity.forEach((quantity) => {
        quantity['productId'] = item.id;
        quantity['productName'] = item.name;
        quantity['orderBy'] = this.orderList.info.name;
        quantity['deliveryAddress'] =
          this.orderList.info.address.line1 +
          this.orderList.info.address.line2 +
          this.orderList.info.address.city +
          this.orderList.info.address.state +
          this.orderList.info.address.zip;
        quantity['poDate'] = this.orderList.poDate;
        json.push(quantity);
      });
    });

    if (this.orderSummaryDownload == 'excel') {
      this.excelService.exportAsExcelFile(json, fileName);
    } else if (this.orderSummaryDownload == 'pdf') {
      this.excelService.exportAsPdfFile(json, fileName);
    }
  }
  editOrder(templateRef: TemplateRef<any>) {
    this.onEdit = true;
    this.dialog.open(templateRef, {
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy(),
    });
    this.cartForm.enable();
  }
  approveRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
  saveOrder() {
    this.onEdit = false;
    this.cartForm.disable();
    console.log(this.cartForm.value.items);
    console.log(this.orderList);
    this.orderList.items.forEach((items) => {
      this.cartForm.value.items.forEach((cartItems) => {
        if (items.id == cartItems.id) {
          items['quantity'] = cartItems['quantity'];
        }
      });
    });
    // this.orderList['items'] = this.cartForm.value.items
    console.log(this.orderList);
  }
  approveRevisedOrder() {
    this.orderList.revisedItems.available = true;

    setTimeout(()=>{
      this.myStepper.selectedIndex = 1
  }, 0);
  }
  approvePayment() {
    this.orderList.paymentDetails.isApproved = true;
    setTimeout(()=>{
      this.myStepper.selectedIndex = 2
  }, 0);
  }
  updateShipmentInfo() {
    this.orderList.shipmentDetails.available = true;
  }
  createForm() {
    this.cartForm = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
    let index = 0;
    this.orderList.items.forEach((product) => {
      this.items().push(
        this.formBuilder.group({
          id: [product.id, Validators.required],
          quantity: this.formBuilder.array([]),
        })
      );
      product.quantity.forEach((quantity) => {
        this.quantity(index).push(
          this.formBuilder.group({
            size: [quantity.size, Validators.required],
            quantity: [
              quantity.quantity,
              {
                validators: [
                  Validators.required,
                  Validators.min(0),
                  // Validators.max(quantity.stock),
                ],
              },
            ],
          })
        );
      });
      index += 1;
    });
    console.log(this.cartForm.controls);
    this.cartForm.disable();
  }
  items(): FormArray {
    return this.cartForm.get('items') as FormArray;
  }
  quantity(id): FormArray {
    return this.items().at(id).get('quantity') as FormArray;
  }
}
