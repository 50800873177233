<div class="container-fluid product-cont pb-5 ">
  <div class="container" *ngIf="selectedProduct ; else shimmer">
    <div class="py-3 row">
      <button class="btn back-btn" routerLink="/product/{{category}}/{{subCategory}}"> <img
          src="/assets/icons/arrow-left-grey.svg" width="7px" class="mr-2">BACK</button>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4 px-0">
        <div class="product-img">
          <carousel [interval]="myInterval" [(activeSlide)]="activeSlideIndex">
            <slide *ngFor="let slide of selectedProduct.imgPath; let index=index">

              <div class="img-zoom-container" (mouseenter)="imageZoom('myimage',index, 'myresult');"
                (mouseleave)="clearZoom()">
                <img id="myimage{{index}}" [src]="slide" alt="image slide" style="display: block; width: 100%;">
              </div>
              <!-- <img [src]="slide" alt="image slide" style="display: block; width: 100%;"> -->
            </slide>
          </carousel>
        </div>
      </div>
      <div class="col-md-6 pl-md-5 mb-4">
        <div class="d-flex cat-cont">
          <div class="mr-3">
            <a routerLink="/product/{{selectedProduct.category}}">
              {{selectedProduct.category}}
            </a>
          </div>
          <div class="mr-3"><img src="/assets/icons/arrow-right-grey.svg" width="100%"></div>
          <div>
            <a routerLink="/product/{{selectedProduct.category}}/{{selectedProduct.subcategory}}">
              {{selectedProduct.subcategory}}
            </a>
          </div>
        </div>
        <div class="product-name-cont">
          {{selectedProduct.name}}
        </div>
        <div class="price-cont">
          ₹ {{selectedProduct.price.from}} - {{selectedProduct.price.to}}
        </div>
        <div class="sizechart-cont mt-3">
          <div class="d-flex align-items-center">
            <div class="mr-2 "><img class="scale-icon" src="/assets/icons/scale.svg"></div>
            <div>SIZE CHART</div>
          </div>
        </div>
        <div class="input-size-cont">
          <form [formGroup]="form" (ngSubmit)="addToCart(selectedProduct.id)">
            <div class="input-parent-cont">
              <ng-container *ngFor="let item of selectedProduct.items; let i= index">
                <div class="d-flex flex-column">
                  <div *ngIf="item.stock>0">
                    <div class="input-cont">
                      <div class="input-label" [ngClass]="{'active' : showProduct}">
                        <label>{{item.size}}</label>
                      </div>
                      <div class="input-size">
                        <input min="0" max="{{item.stock}}" placeholder="0" type="number"
                          formControlName="{{item.size}}">
                      </div>
                    </div>
                  </div>
                  <div class="stock-cont" *ngIf="form.invalid && item.stock>0">{{item.stock}}</div>
                </div>
              </ng-container>
            </div>
            <div class="error-cont d-flex flex-row" *ngIf="form.invalid">
              <div class="error-svg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                  id="Capa_1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;"
                  xml:space="preserve">
                  <circle style="fill:#D75A4A;" cx="25" cy="25" r="25" />
                  <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                    points="16,34 25,25 34,16   " />
                  <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                    points="16,16 25,25 34,34   " />
                </svg>
              </div>
              <div>
                Available Stocks
              </div>
            </div>
            <div class="error-cont d-flex flex-row" *ngIf="!cartFormValid">
              <div class="error-svg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                  id="Capa_1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;"
                  xml:space="preserve">
                  <circle style="fill:#D75A4A;" cx="25" cy="25" r="25" />
                  <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                    points="16,34 25,25 34,16   " />
                  <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                    points="16,16 25,25 34,34   " />
                </svg>
              </div>
              <div>
                Please enter the valid stock
              </div>
            </div>
            <div>
              <!-- <button class="btn btn-sr-cart" [disabled]="!form.valid || form.pristine"
                (click)="addToCart(selectedProduct.id); openSnackBar('Product has been added to cart', 'OK')">ADD TO
                CART</button> -->
              <button class="btn btn-sr-cart mt-4">ADD TO CART</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="product-info-cont">
      <div class="row br ds overflow-hidden">
        <div class="col-md-8 desc-cont">
          <div class="product-info-cont-title">Description</div>
          <div>
            <div *ngFor="let desc of selectedProduct.description">
              <div class="desc-value">
                <li>{{desc.desc}}</li>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 sepc-cont">
          <div class="product-info-cont-title">Specification</div>
          <div class="" *ngFor="let spec of selectedProduct.specification; let i = index">
            <div class="d-flex justify-content-between">
              <div class="spec-title">{{spec.title}}</div>
              <div class="spec-value">{{spec.value}}</div>
            </div>
            <div *ngIf="i != selectedProduct.specification.length - 1">
              <hr class="spec-hr">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="myresult" class="img-zoom-result" [ngClass]="{'active': zoomEnabled}"></div>
</div>

<ng-template #shimmer>
  <div class="container shimmer-cont">
    <div class="row">
      <div class="col-md-6">
        <div id="square" class="shimmer"></div>
      </div>
      <div class="col-md-6">
        <div id="content">
          <div id="title" class="shimmer"></div>
          <div id="desc">
            <div class="line shimmer"></div>
            <div class="line shimmer"></div>
            <div class="line shimmer"></div>
            <div class="line shimmer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
