import { Component, Input, OnInit } from '@angular/core';
/** Admin Side Nav bar Component */
@Component({
  selector: 'app-a-nav',
  templateUrl: './a-nav.component.html',
  styleUrls: ['./a-nav.component.css']
})
export class ANavComponent implements OnInit {
  /**Variable to handle Open and close for Menu bar */
  @Input() menuOpened:boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
