<div class="sr-container py-4">
  <mat-horizontal-stepper #stepper labelPosition="bottom">
    <mat-step completed="true" editable="false">
      <ng-template matStepLabel>
        <div class="stepper-number">01</div>
        <div class="stepper-title">
          Placed Order
        </div>
      </ng-template>
      <div class="stepper-info my-3">
        <img
          matTooltip="Your order will verify with our available stock and our team will send you the revised order shortly. Please be patient or contact our manager to know more."
          class="mr-2" src="/assets/icons/info-icon.svg"> You order has been placed! Once the site manager approved. You
        will be notified.
      </div>
      <hr>
      <div class="stepper-heading bold">Order Summary</div>
      <div>
        <div class="row" *ngIf="orderList;else shimmerOrderList">
          <div class="col-md-8">
            <div class="col-12 order-item-cont py-2" *ngFor="let order of orderList.items; let i=index">
              <div class="row row-cols-1 row-cols-sm-2">
                <div class="col order-img-cont">
                  <div><img width="100%" [src]="order.imgPath" alt=""></div>
                </div>
                <div class="col px-0">

                  <div class="item-id mb-1">ID: {{order.id}}</div>
                  <div class="item-name mb-3">{{order.name}}</div>
                  <div>
                    <div class="input-parent-cont">
                      <div *ngFor="let quan of order.quantity; let quanIndex = index">
                        <div class="d-flex flex-column">
                          <div class="input-cont">
                            <div class="input-label"><label>{{quan.size}}</label></div>
                            <div class="input-size">
                              <input placeholder="size" min="0" [value]="quan.quantity" type="number" disabled />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="row download-cont">
              <div class="col-12 mb-3 download-cont-text">
                DOWNLOAD ORDER SUMMARY
              </div>
              <div class="col-auto mb-2">
                <div class="format-input">
                  <div class="format-input-download" (click)="orderSummaryExcel()">
                    <img src="/assets/icons/download-icon-orange.svg" alt="">
                  </div>
                  <mat-form-field appearance="fill">
                    <!-- <mat-label>Format</mat-label> -->
                    <mat-select [(ngModel)]="orderSummaryDownload" name="orderSummaryDownload" value="0">
                      <mat-option value="excel">EXCEL</mat-option>
                      <mat-option value="pdf">PDF</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="price-details-cont">
              <div class="price-title">PRICE DETAILS</div>
              <hr class="price-hr">
              <div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Actual Price</div>
                  <div class="col-6 text-right pd-value">₹ 5451</div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Discount</div>
                  <div class="col-6 text-success text-right pd-value">- ₹ 0</div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Tax</div>
                  <div class="col-6 text-right pd-value">₹ 0</div>
                </div>
              </div>
              <hr class="price-hr">
              <div class="row align-items-end">
                <div class="col-6 mb-2 total-price-title">Total Price</div>
                <div class="col-6 text-right total-price">₹ 51515</div>
              </div>
            </div>
            <div class="info-cont mt-3">
              <div class="bank-title mb-3">ORDER DETAILS</div>
              <div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">PO ID</div>
                  <div class="col-6 text-right pd-value">PO546084</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">PO DATE</div>
                  <div class="col-6 text-right pd-value">14-SEP-21</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">PO QUANTITY</div>
                  <div class="col-6 text-right pd-value">243</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">ORDERED BY</div>
                  <div class="col-6 text-right pd-value">SAMPLET NAME</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-12 mb-2 pd-title">DELIVERY ADDRESS</div>
                  <div class="col-12 pd-value">No 89 Sample street,
                    K.K Nagar,
                    trichy,<br>
                    tamil nadu,
                    6231021</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step completed="true" editable="true">
      <ng-template matStepLabel>
        <div class="stepper-number">02</div>
        <div class="stepper-title">
          REVISED ORDER
        </div>
      </ng-template>
      <div class="row">
        <div class="col-md-6 stepper-info my-3"><img
            matTooltip="Your order has been revised with our available stock. Please approve the revised order to proceed. If the revised order doesn't meet your requirement please reject the order."
            class="mr-2" src="/assets/icons/info-icon.svg"> Your order has been revised.
        </div>
        <div class="col-md-6 d-flex justify-content-center justify-content-md-end align-items-center">
          <div class="row">
            <div class="col-auto "><button class="btn btn-sr-danger">REJECT</button></div>
            <div class="col-auto"><button class="btn btn-sr-primary" (click)="acceptRevisedOrder()">ACCEPT</button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="stepper-heading bold">Order Summary</div>
      <div>
        <div id="pdfTable" #pdfTable class="row" *ngIf="orderList;else shimmerOrderList">

          <div class="col-md-8">
            <div class="col-12 order-item-cont py-2" *ngFor="let order of orderList.revisedItems.data; let i=index">
              <div class="row row-cols-1 row-cols-sm-2">
                <div class="col order-img-cont">
                  <div><img width="100%" [src]="order.imgPath" alt=""></div>
                </div>
                <div class="col px-0">

                  <div class="item-id mb-1">ID: {{order.id}}</div>
                  <div class="item-name mb-3">{{order.name}}</div>
                  <div>
                    <div class="input-parent-cont">
                      <div *ngFor="let quan of order.quantity; let quanIndex = index">
                        <div class="d-flex flex-column">
                          <div class="input-cont">
                            <div class="input-label"><label>{{quan.size}}</label></div>
                            <div class="input-size">
                              <input placeholder="size" min="0" [value]="quan.quantity" type="number" disabled />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row download-cont">
              <div class="col-12 mb-3 stepper-heading">
                Download revised order summary
              </div>
              <div class="col-auto mb-2">
                <div class="format-input">
                  <div class="format-input-download">
                    <img src="/assets/icons/download-icon-orange.svg" alt="">
                  </div>
                  <mat-form-field appearance="fill">
                    <!-- <mat-label>Format</mat-label> -->
                    <mat-select [(ngModel)]="orderSummaryDownload" name="orderSummaryDownload" value="0">
                      <mat-option value="excel">EXCEL</mat-option>
                      <mat-option value="pdf">PDF</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

            </div>
            <div class="price-details-cont">
              <div class="price-title">REVISED PRICE DETAILS</div>
              <hr class="price-hr">
              <div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Actual Price</div>
                  <div class="col-6 text-right pd-value">₹ 5451</div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Discount</div>
                  <div class="col-6 text-success text-right pd-value">- ₹ 0</div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">Tax</div>
                  <div class="col-6 text-right pd-value">₹ 0</div>
                </div>
              </div>
              <hr class="price-hr">
              <div class="row align-items-end">
                <div class="col-6 mb-2 total-price-title">Total Price</div>
                <div class="col-6 text-right total-price">₹ 53515</div>
              </div>
            </div>
            <div class="info-cont mt-3">
              <div class="bank-title mb-3">ORDER DETAILS</div>
              <div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">PO ID</div>
                  <div class="col-6 text-right pd-value">PO546084</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">REVISED DATE</div>
                  <div class="col-6 text-right pd-value">14-SEP-21</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">REVISED QUANTITY</div>
                  <div class="col-6 text-right pd-value">243</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">ORDERED BY</div>
                  <div class="col-6 text-right pd-value">SAMPLET NAME</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-12 mb-2 pd-title">DELIVERY ADDRESS</div>
                  <div class="col-12 pd-value">No 89 Sample street,
                    K.K Nagar,
                    trichy,<br>
                    tamil nadu,
                    6231021</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step completed="true" editable="false">
      <ng-template matStepLabel>
        <div class="stepper-number">03</div>
        <div class="stepper-title">
          PAYMENT
        </div>
      </ng-template>
      <ng-container *ngIf="orderList;else shimmerPayment">

        <div class="row" *ngIf="orderList.paymentDetails.available">
          <div class="col-md-12 stepper-info my-3"><img class="mr-2" src="/assets/icons/info-icon.svg"> Your payment
            details have been submitted please wait until our accounts team verify. Thanks for your
            patience.
          </div>
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div class="stepper-heading bold">Payment Info</div>
        <div class="row">
          <div class="col-md-8 my-2">
            <div>Please proceed the payment with account details given below and attach the Transaction ID and proof.
            </div>
            <div class="payment-info-cont">
              <div class="row mb-2">
                <div class="col-6 pd-title">INVOICE NO</div>
                <div class="col-6 text-right pd-value">INV2108408</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">INVOICE DATE</div>
                <div class="col-6 text-right pd-value">14-SEP-21</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">ORDER ID</div>
                <div class="col-6 text-right pd-value">PO268505140850</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">ORDER BY</div>
                <div class="col-6 text-right pd-value">SAMPLE NAME</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">REVISED PO QUALITY</div>
                <div class="col-6 text-right pd-value">260</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">REVISED PO VALUE</div>
                <div class="col-6 text-right pd-value">₹ 108089</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="download-cont px-0 d-flex justify-content-end">
              <div class="col-auto px-0 mb-3">
                <button class="btn btn-sr-icon d-flex align-items-end d-flex">
                  <div class="mr-2">
                    <img src="/assets/icons/download-icon.svg" alt="">
                  </div>
                  <div>
                    Download Invoice
                  </div>
                </button>

              </div>
            </div>
            <div class="bank-details-cont ">
              <div class="bank-title mb-3">BANK DETAILS</div>
              <div>
                <div class="row mb-2">
                  <div class="col-6 pd-title">BANK</div>
                  <div class="col-6 text-right pd-value">SBI</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">ACCOUNT NO</div>
                  <div class="col-6 text-right pd-value">2685040850</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">IFSC CODE</div>
                  <div class="col-6 text-right pd-value">648048</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">BANCH NAME</div>
                  <div class="col-6 text-right pd-value">K.K NAGAR</div>
                </div>
                <hr class="bank-hr">

                <div class="row mb-2">
                  <div class="col-6 pd-title">ACC TYPE</div>
                  <div class="col-6 text-right pd-value">CURRENT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!orderList.paymentDetails.available">
          <div class="col-12 stepper-heading">Payment Proof <img
              matTooltip="Once the payment is processed to our bank account. Upload the screenshot of the Transaction and update the ID and Date below. Once it has been verified by our Accounts team your order will be shipped."
              class="mr-2" src="/assets/icons/info-icon.svg" class="info-icon"> </div>
          <div class="col-12 mt-3">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>Transaction ID</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-auto">
                  <mat-form-field color="accent" class="payment-date-picker" appearance="fill">
                    <mat-label>Payment date</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-sr-secondary d-flex">
                    <div>
                      <svg class="upload-file-svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                        width="24px" fill="#ff6631">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                          d="M20 6h-8l-2-2H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-8-4h2v2h2v-2h2v-2h-2v-2h-2v2h-2z" />
                      </svg>
                    </div>
                    <div class="upload-file-text">
                      UPLOAD FILE
                    </div>
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </ng-container>
    </mat-step>
    <mat-step completed="false" editable="false">
      <ng-template matStepLabel>
        <div class="stepper-number">04</div>
        <div class="stepper-title">
          SHIPMENT
        </div>
      </ng-template>
      <ng-container *ngIf="orderList;else shimmerPayment">
        <div *ngIf="!orderList.shipmentDetails.available" class="packing-cont my-5 row">
          <div class="col-12 packing-svg">
            <ng-lottie height="250px" [options]="optionsShipping"></ng-lottie>
          </div>
          <div class="col-12 packing-text justify-content-center d-flex align-items-center ml-2">You order has been
            packing by our team. Please
            be patience.</div>
        </div>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<ng-template #shimmerOrderList>
  <app-shimmer [shimmer]="'orderList'"></app-shimmer>
</ng-template>
<ng-template #shimmerPayment>
  <app-shimmer [shimmer]="'orderPayment'"></app-shimmer>
</ng-template>
