import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/service/cart.service';
import { SubCategoryService } from 'src/app/service/sub-category.service';

@Component({
  selector: 'app-c-nav',
  templateUrl: './c-nav.component.html',
  styleUrls: ['./c-nav.component.css']
})
export class CNavComponent implements OnInit {
  cartCountVar = 0;
  menuList : any;
  isMenuOpened = false;
  isSubMenuOpened = false;
  constructor(private cartService : CartService, private subCategoryService : SubCategoryService) {

  }

  ngOnInit(): void {

    this.cartService.cartCountObservable.subscribe(res =>{
        this.cartCountVar = res
      }
    )
    this.subCategoryService.getSubCategory().subscribe(res => {
      this.menuList = res
      console.log(res)
    })
  }
  openMenu(){
    if(this.isMenuOpened){
    this.isSubMenuOpened = false
    }
    this.isMenuOpened = !this.isMenuOpened
  }
  subMenu(){
    this.isSubMenuOpened = !this.isSubMenuOpened
  }
  closeSubMenu(){
    this.isSubMenuOpened = false
  }
  closeMenu(){
    this.isMenuOpened = false
    this.isSubMenuOpened = false
  }
}
