import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  cartCount = 0
  constructor() { }

  getCartCount(len){
    this.cartCount = len
    console.log(this.cartCount)
  }
}
