<div class="menu-cont " [ngClass]="{'active': menuOpened}">
  <div class="icon-holder py-3">
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/'" >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" id="dashboard" width="24" height="24" viewBox="0 0 24 24">
          <rect id="Rectangle_164" data-name="Rectangle 164" width="24" height="24" fill="none" />
          <path id="Path_232" data-name="Path 232"
            d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM5,19V5h6V19Zm14,0H13V12h6Zm0-9H13V5h6Z"
            fill="#fff" />
        </svg>
      </div>
        <div class="icon-text">Dashboard</div>

    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/orders'" routerLinkActive="active">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" id="order" width="24" height="24" viewBox="0 0 24 24">
          <g id="Group_182" data-name="Group 182">
            <rect id="Rectangle_162" data-name="Rectangle 162" width="24" height="24" fill="none" />
          </g>
          <g id="Group_184" data-name="Group 184">
            <g id="Group_183" data-name="Group 183">
              <path id="Path_231" data-name="Path 231"
                d="M20,2H4A2.074,2.074,0,0,0,2,4V7.01A2.02,2.02,0,0,0,3,8.7V20a2.149,2.149,0,0,0,2,2H19a2.149,2.149,0,0,0,2-2V8.7a2.02,2.02,0,0,0,1-1.69V4A2.074,2.074,0,0,0,20,2ZM19,20H5V9H19ZM20,7H4V4H20Z"
                fill="#fff" />
              <rect id="Rectangle_163" data-name="Rectangle 163" width="6" height="2" transform="translate(9 12)"
                fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
        <div class="icon-text">Orders</div>

    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/payment'" routerLinkActive="active">
      <div>

        <svg xmlns="http://www.w3.org/2000/svg" id="payment" width="24" height="24" viewBox="0 0 24 24">
          <path id="Path_229" data-name="Path 229" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_230" data-name="Path 230" d="M21,7.28V5a2.006,2.006,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V16.72A2,2,0,0,0,22,15V9a2,2,0,0,0-1-1.72ZM20,9v6H13V9ZM5,19V5H19V7H13a2.006,2.006,0,0,0-2,2v6a2.006,2.006,0,0,0,2,2h6v2Z" fill="#fff"/>
          <circle id="Ellipse_40" data-name="Ellipse 40" cx="1.5" cy="1.5" r="1.5" transform="translate(14.5 10.5)" fill="#fff"/>
        </svg>
      </div>
        <div class="icon-text">Payments</div>
    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/report-center'" routerLinkActive="active">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" id="review-center" width="24" height="24" viewBox="0 0 24 24">
          <rect id="Rectangle_165" data-name="Rectangle 165" width="24" height="24" fill="none"/>
          <path id="Path_233" data-name="Path 233" d="M20,6H12L10,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20.77A1.236,1.236,0,0,0,22,18.77V8A2.006,2.006,0,0,0,20,6Zm0,12H4V6H9.17l2,2H20Zm-2-6H6V10H18Zm-4,4H6V14h8Z" fill="#fff"/>
        </svg>
      </div>
        <div class="icon-text">Report Center</div>

    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/customer'" routerLinkActive="active">

      <div>

        <svg xmlns="http://www.w3.org/2000/svg" id="customers" width="24" height="24" viewBox="0 0 24 24">
          <g id="Group_185" data-name="Group 185">
            <path id="Path_234" data-name="Path 234" d="M0,0H24V24H0Z" fill="none"/>
          </g>
          <g id="Group_187" data-name="Group 187">
            <g id="Group_186" data-name="Group 186">
              <path id="Path_235" data-name="Path 235" d="M4,18v-.65a.946.946,0,0,1,.41-.81A10.889,10.889,0,0,1,10,15a.22.22,0,0,1,.08.01,6.886,6.886,0,0,1,.59-1.98c-.22-.02-.44-.03-.67-.03a12.876,12.876,0,0,0-6.61,1.82A2.922,2.922,0,0,0,2,17.35V20h9.26a7.133,7.133,0,0,1-.97-2Z" fill="#fff"/>
              <path id="Path_236" data-name="Path 236" d="M10,12A4,4,0,1,0,6,8,4,4,0,0,0,10,12Zm0-6A2,2,0,1,1,8,8,2.006,2.006,0,0,1,10,6Z" fill="#fff"/>
              <path id="Path_237" data-name="Path 237" d="M20.75,16a4.338,4.338,0,0,0-.06-.63l1.14-1.01-1-1.73-1.45.49a3.647,3.647,0,0,0-1.08-.63L18,11H16l-.3,1.49a3.647,3.647,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1.01a4.338,4.338,0,0,0-.06.63,4.338,4.338,0,0,0,.06.63l-1.14,1.01,1,1.73,1.45-.49a3.647,3.647,0,0,0,1.08.63L16,21h2l.3-1.49a3.647,3.647,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1.01A4.338,4.338,0,0,0,20.75,16ZM17,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,17,18Z" fill="#fff"/>
            </g>
          </g>
        </svg>
      </div>
        <div class="icon-text">Customers</div>
    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/inventory'" routerLinkActive="active">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM6.24 5h11.52l.81.97H5.44l.8-.97zM5 19V8h14v11H5zm8.45-9h-2.9v3H8l4 4 4-4h-2.55z"/></svg>
      </div>
        <div class="icon-text">Inventory</div>
    </div>
    <div class="icon d-flex align-items-center" [routerLink]="'/admin/return-request'" routerLinkActive="active">

      <div>

        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 14h4v-4h-4V7l-5 5 5 5zm7-11h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"/></svg>
      </div>
        <div class="icon-text">Return Request</div>
    </div>
  </div>
</div>
