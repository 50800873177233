import { Component } from '@angular/core';
import { SpinnerService } from './service/spinner.service';
import animSuccess from '../assets/lottie/success-check.json';
import animPending from '../assets/lottie/pending.json';
import { AnimationOptions } from 'ngx-lottie';
import { CartService } from './service/cart.service';

const pathSuccess = `data:text/json;base64,${btoa(
  JSON.stringify(animSuccess)
)}`;
const pathLoader = `data:text/json;base64,${btoa(JSON.stringify(animPending))}`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'sellerrocket';
  loader: Boolean = false;
  spinner: Boolean = false;
  success: Boolean = false;
  successText: any;
  successSvg = false;
  loaderSvg = true;
  options: AnimationOptions = {
    path: pathSuccess,
    loop: false,
  };
  optionsLoader: AnimationOptions = {
    path: pathLoader,
    loop: true,
  };
  constructor(
    private spinnerService: SpinnerService,
    private cartService: CartService
  ) {
    this.spinnerService.spinnerObservable.subscribe((res) => {
      this.spinner = res;
    });
    this.spinnerService.loaderObservable.subscribe((res) => {
      this.loader = res;
    });
    this.spinnerService.successObservable.subscribe((res) => {
      if (!res.status) {
        this.loaderSvg = false;
        this.successSvg = true;
        setTimeout(() => {
          this.success = false;
          this.loaderSvg = true;
          this.successSvg = false;
        }, 2500);
      } else {
        this.success = true;
        this.successText = res.message;
        console.log(res);
      }
    });
  }

}
