<div class="px-g ">
  <div class="sr-container px-0 pt-4" *ngIf="orderList">
    <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
      <!-- <mat-stepper [orientation]="(stepperOrientation | async)!" labelPosition="bottom" [linear]="true" #stepper> -->
      <mat-step [completed]="orderList.revisedItems.available " editable="false">
        <ng-template matStepLabel>
          <div class="stepper-number">01</div>
          <div class="stepper-title">
            Revise order
          </div>
        </ng-template>
        <!-- <div class="stepper-info mb-3">You order has been placed! Once the site manager approved. You will be notified.</div> -->
        <div *ngIf="orderList.revisedItems.available">
          <div class="revised-approved-cont">
            This Order has already been revised.
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end btn-cont px-0" *ngIf="!orderList.revisedItems.available">
          <div><button class="btn btn-sr-secondary mr-4" (click)="editOrder(editOrderRef)">
              <ng-container>EDIT</ng-container>
              <ng-template #onSave>SAVE</ng-template>
            </button></div>
          <div><button class="btn btn-sr-primary" (click)="approveRef(approveOrderRef)">APPROVE</button></div>

        </div>
        <hr>
        <div class="stepper-heading bold">Order Summary</div>
        <div>
          <div class="row" *ngIf="orderList">
            <div class="col-md-8 px-0">
              <form>
                <div class="col-12 px-0">
                  <div class="col-12 order-item-cont py-2" *ngFor="let order of orderList.items; let i=index">
                    <div class="row row-cols-1 row-cols-sm-2">
                      <div class="col-12 px-0 order-img-cont">
                        <div><img width="100%" [src]="order.imgPath" alt=""></div>
                      </div>
                      <div class="col py-3">
                        <div class="item-id mb-1">ID: {{order.id}}</div>
                        <div class="item-name mb-3">{{order.name}}</div>
                        <div>
                          <div class="input-parent-cont">
                            <div *ngFor="let quan of order.quantity; let quanIndex = index">
                              <div class="d-flex flex-column">
                                <div class="input-cont">
                                  <div class="input-label"><label>{{quan.size}}</label></div>
                                  <div class="input-size">
                                    <input placeholder="" min="0" type="number" value="{{quan.quantity}}" disabled />
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="stock-cont" *ngIf="cartForm.invalid && cart[i].items[quanIndex].stock>0">
                            {{cart[i].items[quanIndex].stock}}</div> -->

                            </div>
                          </div>
                          <!-- <div class="mt-3" *ngIf="cartForm.invalid">Enter valid Stock</div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-4 ">
              <div class="row download-cont">

                <div class="col-12 mb-3 stepper-heading">
                  Download order summary
                </div>
                <div class="col-auto mb-2">
                  <div class="format-input">
                    <div class="format-input-download" (click)="orderSummaryExcel()">
                      <img src="/assets/icons/download-icon-orange.svg" alt="" >
                    </div>
                    <mat-form-field appearance="fill">
                      <!-- <mat-label>Format</mat-label> -->
                      <mat-select [(ngModel)]="orderSummaryDownload" name="orderSummaryDownload" value="0">
                        <mat-option value="excel">EXCEL</mat-option>
                        <mat-option value="pdf">PDF</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!-- <div class="col-auto d-flex align-items-center mb-2">
                  <button class="btn download-btn d-flex align-items-end d-flex" (click)="orderSummaryExcel()">
                    <div class="mr-2">Download</div>
                    <div class="dowload-btn-img">
                      <img width="20px" src="/assets/icons/file_download_black.svg">
                    </div>
                  </button>
                </div> -->
              </div>
              <div class="price-details-cont">
                <div class="price-title">PRICE DETAILS</div>
                <hr class="price-hr">
                <div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Actual Price</div>
                    <div class="col-6 text-right pd-value">₹ 5451</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Discount</div>
                    <div class="col-6 text-success text-right pd-value">- ₹ 0</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Tax</div>
                    <div class="col-6 text-right pd-value">₹ 0</div>
                  </div>
                </div>
                <hr class="price-hr">
                <div class="row align-items-end">
                  <div class="col-6 mb-2 total-price-title">Total Price</div>
                  <div class="col-6 text-right total-price">₹ 51515</div>
                </div>
              </div>
              <div class="info-cont mt-3">
                <div class="bank-title mb-3">ORDER DETAILS</div>
                <div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">PO ID</div>
                    <div class="col-6 text-right pd-value">{{orderList.orderID}}</div>
                  </div>
                  <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">PO DATE</div>
                    <div class="col-6 text-right pd-value">{{orderList.poDate}}</div>
                  </div>
                  <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">PO QUANTITY</div>
                    <div class="col-6 text-right pd-value">243</div>
                  </div>
                  <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">ORDERED BY</div>
                    <div class="col-6 text-right pd-value">{{orderList.info.name}}</div>
                  </div>
                  <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-12 mb-2 pd-title">DELIVERY ADDRESS</div>
                    <div class="col-12 pd-value">{{orderList.info.address.line1}},
                      {{orderList.info.address.line2}},
                      {{orderList.info.address.city}},<br>
                      {{orderList.info.address.state}},
                      {{orderList.info.address.zip}}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- <button mat-button matStepperNext>Next</button> -->
      </mat-step>
      <!-- <mat-step label="REVISED ORDER" completed="true">
      <div class="stepper-info mb-3">Your order has been revised.</div>
      <hr>
      <div class="stepper-heading">Order Summary</div>
      <div>
        <div id="pdfTable" #pdfTable class="row" *ngIf="orderList">

            <div class="col-md-8" >
              <div class="col-12 order-item-cont py-2" *ngFor="let order of orderList.revisedItems.data; let i=index">
                <div class="row row-cols-1 row-cols-sm-2" >
                  <div class="col order-img-cont">
                    <div><img width="100%" [src]="order.imgPath" alt=""></div>
                  </div>
                  <div class="col px-0">

                        <div class="item-id mb-1">ID: {{order.id}}</div>
                        <div class="item-name mb-3">{{order.name}}</div>
                        <div>
                          <div class="input-parent-cont">
                            <div *ngFor="let quan of order.quantity; let quanIndex = index">
                              <div class="d-flex flex-column">
                                <div class="input-cont">
                                  <div class="input-label"><label>{{quan.size}}</label></div>
                                  <div class="input-size">
                                    <input placeholder="size" min="0" [value]="quan.quantity" type="number" disabled/>
                                  </div>
                                </div>
                              </div>
                            </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row download-cont">
                <div class="col-12 mb-3 stepper-heading">
                  Download revised order summary
                </div>
                <div class="col-auto">
                  <mat-form-field appearance="outline">
                  <mat-label>Format</mat-label>
                  <mat-select (value)="0">
                    <mat-option value="0">PDF</mat-option>
                    <mat-option value="1">EXCEL</mat-option>
                  </mat-select>
                </mat-form-field></div>
                <div class="col-auto">
                  <button class="btn btn-sr-primary">DOWNLOAD</button>
                </div>
              </div>
              <div class="price-details-cont">
                <div class="price-title">REVISED PRICE DETAILS</div>
                <hr class="price-hr">
                <div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Actual Price</div>
                    <div class="col-6 text-right pd-value">₹ 5451</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Discount</div>
                    <div class="col-6 text-success text-right pd-value">- ₹ 0</div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">Tax</div>
                    <div class="col-6 text-right pd-value">₹ 0</div>
                  </div>
                </div>
                <hr class="price-hr">
                <div class="row align-items-end">
                  <div class="col-6 mb-2 total-price-title">Total Price</div>
                  <div class="col-6 text-right total-price">₹ 53515</div>
                </div>
              </div>
              <div class="info-cont mt-3">
                <div class="bank-title mb-3">ORDER DETAILS</div>
                <div>
                  <div class="row mb-2">
                    <div class="col-6 pd-title">PO ID</div>
                    <div class="col-6 text-right pd-value">PO546084</div>
                  </div>
                <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">REVISED DATE</div>
                    <div class="col-6 text-right pd-value">14-SEP-21</div>
                  </div>
                <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">REVISED QUANTITY</div>
                    <div class="col-6 text-right pd-value">243</div>
                  </div>
                <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-6 pd-title">ORDERED BY</div>
                    <div class="col-6 text-right pd-value">SAMPLET NAME</div>
                  </div>
                <hr class="bank-hr">

                  <div class="row mb-2">
                    <div class="col-12 mb-2 pd-title">DELIVERY ADDRESS</div>
                    <div class="col-12 pd-value">No 89 Sample street,
                    K.K Nagar,
                      trichy,<br>
                      tamil nadu,
                   6231021</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <button mat-button >Cancel Order</button>
      <button mat-button matStepperNext>Confirm</button>
    </mat-step> -->
      <mat-step [completed]="orderList.paymentDetails.isApproved" editable="false">
        <ng-template matStepLabel>
          <div class="stepper-number">02</div>
          <div class="stepper-title">
            PAYMENT
          </div>
        </ng-template>
        <div class="row">
          <div class="col-md-6" *ngIf="orderList.paymentDetails.available; else noPaymentStatus">
            <div class="payment-info-cont">
              <div class="stepper-heading bold  mt-2">Payment Proof</div>

              <div class="row mb-2 mt-3">
                <div class="col-6 pd-title">TRANSACTION ID</div>
                <div class="col-6 text-right pd-value">{{orderList.paymentDetails.data.transactionId}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">PAYMENT DATE</div>
                <div class="col-6 text-right pd-value">{{orderList.paymentDetails.data.paymentDate}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">PAYMENT PROOF</div>
                <div class="col-6 d-flex justify-content-end d-flex pd-value">
                  <button class="btn btn-sr-icon d-flex align-items-end d-flex">
                    <div class="mr-2">
                      <img src="/assets/icons/download-icon.svg" alt="">
                    </div>
                    <div>
                      Download
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-12 px-0 d-flex justify-content-end mt-5" *ngIf="!orderList.paymentDetails.isApproved">
                <button class="btn btn-sr-primary" (click)="approveRef(approvePaymentRef)">APPROVE</button></div>
              <div class="col-12 px-0 mt-5" *ngIf="orderList.paymentDetails.isApproved">
                <div class="revised-approved-cont">
                  This Order Payment has already been approved.
                </div>
              </div>
            </div>

          </div>

          <div class="col-md-6 justify-content-end">

            <div class="payment-info-cont ml-auto mt-2 pt-0">
              <div class="stepper-heading mb-2 bold">ORDER INFO</div>

              <div class="row mb-2">
                <div class="col-6 pd-title">INVOICE NO</div>
                <div class="col-6 text-right pd-value">{{orderList.revisedItems.invoiceNo}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">INVOICE DATE</div>
                <div class="col-6 text-right pd-value">{{orderList.revisedItems.rpoDate}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">ORDER ID</div>
                <div class="col-6 text-right pd-value">{{orderList.orderID}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">ORDER BY</div>
                <div class="col-6 text-right pd-value">{{orderList.info.name}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">MOBILE</div>
                <div class="col-6 text-right pd-value">{{orderList.info.mobile}}</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">REVISED PO QUALITY</div>
                <div class="col-6 text-right pd-value">260</div>
              </div>
              <hr class="bank-hr">
              <div class="row mb-2">
                <div class="col-6 pd-title">REVISED PO VALUE</div>
                <div class="col-6 text-right pd-value">₹ 108089</div>
              </div>
            </div>
            <div class="download-cont d-flex justify-content-end">
              <div class="col-auto px-0">
                <button class="btn btn-sr-icon d-flex align-items-end" (click)="orderSummaryExcel()">
                  <div class="mr-2">
                    <img src="/assets/icons/download-icon.svg" alt="">
                  </div>
                  <div>
                    Download Invoice
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>


      </mat-step>
      <mat-step completed="false">
        <ng-template matStepLabel>
          <div class="stepper-number">03</div>
          <div class="stepper-title">
            SHIPMENT
          </div>
        </ng-template>
        <div class="col-md-12 shipment-cont">
          <div class="stepper-heading bold  mt-2">SHIPPING DETAILS</div>
          <form>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mt-3">
              <div class="col">
                <mat-form-field class="contact-full-width" appearance="fill">
                  <mat-label>Tracking Id</mat-label>
                  <input matInput type="text">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="contact-full-width" appearance="fill">
                  <mat-label>Delivery Agency</mat-label>
                  <input matInput type="text">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field color="accent"  appearance="fill">
                  <mat-label>Shipment Date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field color="accent"  appearance="fill">
                  <mat-label>Expected Delivery Date</mat-label>
                  <input matInput [matDatepicker]="dpicker">
                  <mat-datepicker-toggle matSuffix [for]="dpicker"></mat-datepicker-toggle>
                  <mat-datepicker #dpicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!orderList.shipmentDetails.available" class="col-12 px-0 d-flex justify-content-end mt-1">
              <button class="btn btn-sr-secondary" (click)="updateShipmentInfo()">UPDATE INFO</button></div>
          </form>
        </div>
        <hr class="bank-hr mt-3">
        <div class="row d-flex mt-3 px-2">


        </div>
        <div *ngIf="orderList.shipmentDetails.available" class="row d-flex mt-3 px-2 justify-content-space">
          <div class="col-md-6 d-flex shipping-download-cont">
            <button class="btn btn-sr-icon d-flex align-items-end d-flex">
              <div class="mr-2">
                <img src="/assets/icons/download-icon.svg" alt="">
              </div>
              <div>
                Download shipping label
              </div>
            </button>
          </div>
          <div class="col-md-6 d-flex delivery-btn-cont">
            <div class="col-auto mb-3"><button class="btn btn-sr-danger">RETURNED</button></div>
            <div class="col-auto"><button class="btn btn-sr-primary">DELIVERED</button></div>
          </div>
        </div>
      </mat-step>

      <!-- </mat-stepper> -->
    </mat-horizontal-stepper>
  </div>
</div>
<ng-template #editOrderRef>
  <h2 matDialogTitle>Revise Order</h2>
  <mat-dialog-content>
    <div>
      <div class="row" *ngIf="orderList">
        <div class="col-md-12">
          <form [formGroup]="cartForm">
            <div class="col-12" formArrayName="items">
              <div class="col-12 order-item-cont py-2" *ngFor="let cartItems of items().controls; let i=index">
                <div class="row" [formGroupName]="i">
                  <div class="col-12 order-img-cont">
                    <div><img width="100%" [src]="orderList.items[i].imgPath" alt=""></div>
                  </div>
                  <div class="col py-3">
                    <div class="item-id mb-1">ID: {{orderList.items[i].id}}</div>
                    <div class="item-name mb-3">{{orderList.items[i].name}}</div>
                    <div>
                      <div formArrayName="quantity" class="input-parent-cont">
                        <div *ngFor="let quan of quantity(i).controls; let quanIndex = index">
                          <div class="d-flex flex-column">
                            <div [formGroupName]="quanIndex" class="input-cont">
                              <div class="input-label"><label>{{orderList.items[i].quantity[quanIndex].size}}</label>
                              </div>
                              <div class="input-size">
                                <input placeholder="" min="0" type="number" formControlName="quantity" />
                              </div>
                            </div>
                          </div>
                          <!-- <div class="stock-cont" *ngIf="cartForm.invalid && cart[i].items[quanIndex].stock>0">
                        {{cart[i].items[quanIndex].stock}}</div> -->

                        </div>
                      </div>
                      <div class="mt-3" *ngIf="cartForm.invalid">Enter valid Stock</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>


      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button matDialogClose class="btn mb-3 btn-sr-primary" (click)="saveOrder()">SAVE</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #noPaymentStatus>
  <div class="col-md-6 d-flex flex-column align-items-center">
    <div class="clock-svg">
      <ng-lottie  height="220px" [options]="optionsNoPayment"></ng-lottie>
    </div>
    <div class="mb-3 text-center">
      <span class="clock-svg-span">Customer has not approved the revised order yet.</span><br>Please be patience or
      contact the customer to proceed.
    </div>
  </div>
</ng-template>
<ng-template #approveOrderRef>
  <!-- <h2 matDialogTitle>Approve Order</h2> -->
  <mat-dialog-content>
    <div class="d-flex flex-column align-items-center px-5">
      <div>
        <img width="100px" src="/assets/icons/approved.svg" alt="">
      </div>
      <div class="text-center mt-3">
        <b>Approve Order</b><br>
        Are sure want to approve the Revised order?
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center">
    <button matDialogClose class="btn btn-sr-secondary mr-3">Cancel</button>
    <button matDialogClose class="btn my-3 btn-sr-primary" (click)="approveRevisedOrder()">APPROVE</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #approvePaymentRef>
  <mat-dialog-content>
    <div class="d-flex flex-column align-items-center px-5">
      <div>
        <img width="100px" src="/assets/icons/approved.svg" alt="">
      </div>
      <div class="text-center mt-3">
        <b>Approve Payment</b><br>Are sure want to approve the Payment for this order?
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center">
    <button matDialogClose class="btn btn-sr-secondary mr-3">Cancel</button>
    <button matDialogClose class="btn my-3 btn-sr-primary" (click)="approvePayment()">APPROVE</button>
  </mat-dialog-actions>
</ng-template>
