<div class="dark-bg">
  <div class="px-g">
    <div class="sr-container">
      <div class="main-graph-cont">
        <div>
          <div class="col-12 d-flex justify-content-space align-items-center">
            <div class="col graph-title">Sales Graph</div>
            <div>
              <div class="dashboard-input">
                <mat-form-field appearance="fill">
                  <mat-label>Sales Year</mat-label>
                  <mat-select (selectionChange)="changeSalesYear($event.value)" [value]="salesGraphData['year']">
                    <mat-option [value]="year" *ngFor="let year of salesGraphYear">{{year}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div style="display: block" class="main-chart">
            <canvas id="mainChart"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="px-g">
  <div class="sr-container">
    <div class="data-cont mt-5">
      <div class="sub-title">ORDER DETAILS</div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4  ">
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="new_orders" transform="translate(-179 -655)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)"
                      fill="#d9d9d9" />
                    <g id="_5350784741580594406" data-name="5350784741580594406" transform="translate(192.004 668)">
                      <path id="Path_248" data-name="Path 248"
                        d="M23,6a.8.8,0,0,0-.06-.3.64.64,0,0,0,0-.07h0l-2-4A1,1,0,0,0,20,1H3a1,1,0,0,0-1,.76l-1,4a.34.34,0,0,0,0,.1A.7.7,0,0,0,1,6V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V6ZM3.78,3h15.6l1,2H3.28ZM21,21H3V7H21Z"
                        fill="#374366" />
                      <path id="Path_249" data-name="Path 249"
                        d="M11.45,18.83a1,1,0,0,0,1.1,0l3-2a1,1,0,1,0-1.1-1.66l-1.45,1V10a1,1,0,0,0-2,0v6.13l-1.45-1a1,1,0,0,0-1.1,1.66Z"
                        fill="#374366" />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">New Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="revised_order" transform="translate(-598 -656)">
                    <g id="Group_219" data-name="Group 219" transform="translate(419 1)">
                      <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#ffd5c7"/>
                    </g>
                    <path id="_854270391582806953" data-name="854270391582806953" d="M17.853,1.492a1.973,1.973,0,0,1,2.79,0l1.564,1.564a1.973,1.973,0,0,1,0,2.79L15.8,12.258a1.973,1.973,0,0,1-1.395.578H11.85a.986.986,0,0,1-.986-.986V9.3A1.973,1.973,0,0,1,11.442,7.9Zm2.959,2.959L19.248,2.887,12.837,9.3v1.564H14.4ZM3.959,3.958a.986.986,0,0,0-.986.986v14.8a.986.986,0,0,0,.986.986h14.8a.986.986,0,0,0,.986-.986V11.849a.986.986,0,1,1,1.973,0V19.74A2.959,2.959,0,0,1,18.755,22.7H3.959A2.959,2.959,0,0,1,1,19.74V4.944A2.959,2.959,0,0,1,3.959,1.985H11.85a.986.986,0,0,1,0,1.973Z" transform="translate(611.301 669.086)" fill="#ff6631" fill-rule="evenodd"/>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Revised Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="paid_order" transform="translate(-1013 -656)">
                    <g id="Group_221" data-name="Group 221" transform="translate(834 1)">
                      <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#fffeee"/>
                    </g>
                    <g id="_19437462271595601936" data-name="19437462271595601936" transform="translate(1024.837 668.619)">
                      <g id="Group_222" data-name="Group 222" transform="translate(3 4.564)">
                        <path id="Path_251" data-name="Path 251" d="M3,7.345A2.3,2.3,0,0,1,5.345,5H8.472" transform="translate(-3 -5)" fill="none" stroke="#ffc300" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                        <path id="Path_252" data-name="Path 252" d="M15.508,16.6h0a3.136,3.136,0,0,1,3.127-3.127h3.127a1.568,1.568,0,0,0,1.564-1.564h0a1.568,1.568,0,0,0-1.564-1.564H5.345A2.352,2.352,0,0,1,3,8V20.508a2.352,2.352,0,0,0,2.345,2.345H21.763a1.568,1.568,0,0,0,1.564-1.564h0a1.568,1.568,0,0,0-1.564-1.564H18.636A3.136,3.136,0,0,1,15.508,16.6Z" transform="translate(-3 -5.655)" fill="none" stroke="#ffc300" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                      </g>
                      <path id="Path_253" data-name="Path 253" d="M10.127,6.909,20.29,3l1.564,3.909H7" transform="translate(-0.873)" fill="none" stroke="#ffc300" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                      <line id="Line_96" data-name="Line 96" x2="2" transform="translate(18.163 15.381)" fill="none" stroke="#ffc300" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                      <line id="Line_97" data-name="Line 97" y2="10" transform="translate(23.163 10.381)" fill="none" stroke="#ffc300" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Paid Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="shipped_order" transform="translate(-1430 -656)">
                    <g id="Group_223" data-name="Group 223" transform="translate(1251 1)">
                      <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#c5deff"/>
                    </g>
                    <g id="_10511101761543238864" data-name="10511101761543238864" transform="translate(1443.575 669.378)">
                      <path id="Path_255" data-name="Path 255" d="M12.264,1.438l7.54,3.77A1.885,1.885,0,0,1,20.85,6.9v8.982A1.885,1.885,0,0,1,19.8,17.564l-7.54,3.77a1.885,1.885,0,0,1-1.687,0l-7.54-3.77A1.885,1.885,0,0,1,2,15.868V6.9A1.885,1.885,0,0,1,3.046,5.208l7.54-3.77a1.885,1.885,0,0,1,1.678,0Z" fill="none" stroke="#0054c2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                      <path id="Path_256" data-name="Path 256" d="M2.32,6.16l9.124,4.562L20.567,6.16" transform="translate(-0.018 -0.283)" fill="none" stroke="#0054c2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                      <line id="Line_98" data-name="Line 98" y1="11.084" transform="translate(11.425 10.439)" fill="none" stroke="#0054c2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Shipped Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="delivered_order" transform="translate(-179 -655)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#b8ffc4"/>
                    <g id="_6099563831558424397" data-name="6099563831558424397" transform="translate(193.064 669.227)">
                      <g id="Group_220" data-name="Group 220">
                        <path id="Path_250" data-name="Path 250" d="M9.324,12.382,6.869,9.832l-1.417,1.46,2.454,2.551-.006.006,1.417,1.46,1.417-1.46h0L16.4,8.01,14.984,6.55ZM17.483,0H4.371A4.371,4.371,0,0,0,0,4.371V17.482a4.371,4.371,0,0,0,4.371,4.371H17.483a4.371,4.371,0,0,0,4.371-4.371V4.371A4.371,4.371,0,0,0,17.483,0Zm2.185,16.39a3.278,3.278,0,0,1-3.278,3.278H5.463A3.278,3.278,0,0,1,2.185,16.39V5.463A3.278,3.278,0,0,1,5.463,2.185H16.39a3.278,3.278,0,0,1,3.278,3.278Z" fill="#01b820"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Delivered Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/return-request">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="returned_order" transform="translate(-179 -655)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#ffd8d8"/>
                    <path id="XMLID_223_" d="M31.76,20.926v12a1.212,1.212,0,0,1-1.209,1.209H12.75a1.209,1.209,0,0,1,0-2.418H29.342V22.044H14.14L15.8,23.737a1.24,1.24,0,0,1,0,1.753,1.169,1.169,0,0,1-1.692,0l-3.748-3.717A1.193,1.193,0,0,1,10,20.926a1.1,1.1,0,0,1,.363-.846l3.748-3.717a1.207,1.207,0,0,1,1.723,1.692L14.14,19.627H30.551A1.294,1.294,0,0,1,31.76,20.926Z" transform="translate(183.472 655.227)" fill="#e12424"/>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Returned Orders</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
      </div>
      <div class="sub-title">PRODUCT DETAILS</div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4  ">
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="all_products" transform="translate(-179 -655)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#b8ffc4"/>
                    <g id="_10511101761543238864" data-name="10511101761543238864" transform="translate(192.303 668.086)">
                      <path id="Path_255" data-name="Path 255" d="M12.559,1.444l7.757,3.879a1.939,1.939,0,0,1,1.076,1.736V16.3a1.939,1.939,0,0,1-1.076,1.736l-7.757,3.879a1.939,1.939,0,0,1-1.736,0L3.067,18.034A1.939,1.939,0,0,1,2,16.289V7.058A1.939,1.939,0,0,1,3.076,5.322l7.757-3.879a1.939,1.939,0,0,1,1.726,0Z" transform="translate(0)" fill="none" stroke="#01b820" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                      <path id="Path_256" data-name="Path 256" d="M2.32,6.16l9.386,4.693L21.092,6.16" transform="translate(-0.01 -0.149)" fill="none" stroke="#01b820" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                      <line id="Line_98" data-name="Line 98" y1="11.403" transform="translate(11.696 10.704)" fill="none" stroke="#01b820" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Total Stocks</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="out_of_stocks" transform="translate(-179 -655)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(179 655)" fill="#ffd8d8"/>
                    <g id="Icons" transform="translate(194 670)">
                      <g id="Rounded" transform="translate(-170 -730)">
                        <g id="Alert" transform="translate(100 674)">
                          <g id="_-Round-_-Alert-_-error_outline" data-name="-Round-/-Alert-/-error_outline" transform="translate(68 54)">
                            <g id="Group_224" data-name="Group 224">
                              <path id="_Icon-Color" data-name="🔹Icon-Color" d="M12.949,7.475A1.1,1.1,0,0,1,14.044,8.57v4.38a1.095,1.095,0,1,1-2.19,0V8.57A1.1,1.1,0,0,1,12.949,7.475ZM12.938,2A10.949,10.949,0,1,0,23.9,12.949,10.944,10.944,0,0,0,12.938,2Zm.011,19.709a8.759,8.759,0,1,1,8.759-8.759A8.757,8.757,0,0,1,12.949,21.709Zm1.095-3.285h-2.19v-2.19h2.19Z" transform="translate(-0.949 -0.949)" fill="#e12424" fill-rule="evenodd"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Out of Stock</div>
            </div>
            <div class="order-data-value">
              2,528
            </div>
          </div>
        </div>
        <div class="col">
          <div class="order-data-cont" routerLink="/admin/orders">
            <div class="d-flex align-items-center">
              <div class="order-data-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                  <g id="low_stocks" transform="translate(-1018 -1137)">
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="25" cy="25" r="25" transform="translate(1018 1137)" fill="#fffeee"/>
                    <path id="_16681813521582806004" data-name="16681813521582806004" d="M12.234,4.247a.949.949,0,0,0-1.628,0l-8.04,13.4a.949.949,0,0,0,.814,1.438H19.46a.949.949,0,0,0,.814-1.438ZM8.978,3.27a2.848,2.848,0,0,1,4.885,0l8.04,13.4a2.848,2.848,0,0,1-2.442,4.314H3.381A2.848,2.848,0,0,1,.938,16.669ZM11.42,7.691a.949.949,0,0,1,.949.949v3.8a.949.949,0,1,1-1.9,0V8.64A.949.949,0,0,1,11.42,7.691Zm0,6.646a.949.949,0,0,1,.949.949v.949a.949.949,0,1,1-1.9,0v-.949A.949.949,0,0,1,11.42,14.337Z" transform="translate(1031.473 1150.113)" fill="#ffd23b" fill-rule="evenodd"/>
                  </g>
                </svg>
              </div>
              <div class="order-data-title">Low Stock</div>
            </div>
            <div class="order-data-value">
              2,205
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
