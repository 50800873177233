import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  url = environment.baseURL;

  constructor(private http: HttpClient) {}

  getOrder() {
    return this.http.get(this.url + '/order.json');
  }
  getOrderList(){
    return this.http.get(this.url + '/order-list.json');
  }
}
