import { Component, OnInit, ViewChild ,TemplateRef} from '@angular/core';
import { ProfileService } from 'src/app/service/profile.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { OrderService } from 'src/app/service/order.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'src/app/service/payment.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {FormGroup, FormControl} from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  profile: any;
  orderList: any = [];
  filterOrderList: any = []
  displayedColumnsOrder: string[]
  status = ['all','pending','revised','approved','shipped','completed','rejected']

  paymentList: any = []
  filterPaymentList: any = []
  displayedColumnsPayment: string[]

  date = new Date();
  downloadForm = new FormGroup({
    format: new FormControl('excel'),
    status: new FormControl('all'),
    start: new FormControl(this.date),
    end: new FormControl(this.date)
  });

  dataSourceOrder = new MatTableDataSource(this.orderList);
  dataSourcePayment = new MatTableDataSource(this.paymentList);

  constructor(private profileService: ProfileService,private orderService : OrderService,private paymentService : PaymentService,  private dialog: MatDialog, public datepipe: DatePipe,private excelService : ExcelGeneratorService) {}

  @ViewChild('sort') sort: MatSort;
  @ViewChild('sortPayment') sortPayment: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorPayment') paginatorPayment: MatPaginator;

  ngOnInit(): void {
    this.getProfile()
    this.orderService.getOrderList().subscribe(res=>{
      this.orderList = res
      this.displayedColumnsOrder = Object.keys(this.orderList[0])
      this.dataSourceOrder = new MatTableDataSource(this.orderList);
      this.dataSourceOrder.sort = this.sort;
      this.dataSourceOrder.paginator = this.paginator;
    })
    this.paymentService.getPaymentList().subscribe(res=>{
      this.paymentList = res
      this.displayedColumnsPayment = Object.keys(this.paymentList[0])
      this.dataSourcePayment = new MatTableDataSource(this.paymentList);
      this.dataSourcePayment.sort = this.sortPayment;
      this.dataSourcePayment.paginator = this.paginatorPayment;
    })
  }

  getProfile() {
    this.profileService.getProfile().subscribe((res) => {
      this.profile = res[0];
    });
  }
  downloadExcel(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '400px',
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  downloadExcelFile(){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `Payment-List-`+latest_date;
    // let downloadList = this.orderList

    let start_date =this.datepipe.transform(this.downloadForm.value.start, 'yyyy-MM-dd')
    let end_date =this.datepipe.transform(this.downloadForm.value.end, 'yyyy-MM-dd')
    let downloadList = this.orderList.filter((order) => order.poDate >= start_date && order.poDate <= end_date)

    if(this.downloadForm.value.status !== "all"){
      downloadList = downloadList.filter((order) => order.status == this.downloadForm.value.status)
    }
    if(this.downloadForm.value.format == 'excel'){
      this.excelService.exportAsExcelFile(downloadList, fileName);
    }else if(this.downloadForm.value.format == 'pdf'){
      this.excelService.exportAsPdfFile(downloadList, fileName);
    }
  }
  filterTable(value){
    this.dataSourceOrder.filter = value.trim().toLocaleLowerCase();
  }
  onFilter(val){
    console.log(val)
    if(val =="all"){
      this.dataSourceOrder = new MatTableDataSource(this.orderList);
      this.dataSourceOrder.sort = this.sort;
      this.dataSourceOrder.paginator = this.paginator;
    }else{
      this.filterOrderList = this.orderList.filter((order) => order.status == val)
      this.dataSourceOrder = new MatTableDataSource(this.filterOrderList);
      this.dataSourceOrder.sort = this.sort;
      this.dataSourceOrder.paginator = this.paginator;

    }

  }
}
