<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">LIST</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">CUSTOMERS</div>
      <div class="col-md-6">
        <form (ngSubmit)="filterTable(search)">
        <div class="search-bar-cont">
          <div class="search-input">

            <input class="form-control" type="text" name="searchText" [(ngModel)]="search" placeholder="Search Customer"
              autocomplete="off">
          </div>
          <button class="search-cont" ><img width="100%" src="/assets/icons/search-bar.svg">
          </button>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="px-g py-5 order-cont">
  <div class="sr-container px-0 ">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="NEW REQUEST">
        <div class="col-12" *ngIf="newCustomerList">
          <div class="row mt-3">
            <div class="col-12 mb-3" *ngFor="let customer of activePageDataChunk">
              <div class="customer-cont br ds">
                <div class="row">
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">NAME</div>
                      <div class="cd-value">{{customer.name}}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">GSTIN</div>
                      <div class="cd-value">{{customer.gstin}}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="customer-detail-cont">
                      <div class="cd-title">SHOP NAME</div>
                      <div class="cd-value" matTooltip="Sample Name get the sampple name .pvt">{{customer.shopName}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">MOBILE</div>
                      <div class="cd-value">{{customer.mobile}}</div>
                    </div>
                  </div>
                  <div class="col-md-3 d-flex align-items-center">
                    <div class="customer-status-cont bold pending">
                      {{customer.status | uppercase}}
                    </div>
                  </div>
                </div>
                <div routerLink='{{customer.gstin}}'
                  class="view-btn br d-flex align-items-center justify-content-center">
                  <div>
                    <div>
                      <img src="/assets/icons/arrow-white-right.svg" alt="">
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
            <mat-paginator class="mt-2 w-100 ds" #paginator [length]="newCustomerList.length" [pageSize]="pageSize"
              [pageSizeOptions]="[5, 10, 20]" (page)="onPendingPageChanged($event)"></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="ALL CUSTOMERS">
        <div class="col-12" *ngIf="customerList">
          <div class="row mt-3">
            <div class="col-12 mb-3" *ngFor="let customer of activeAllPageDataChunk">
              <div class="customer-cont ds">
                <div class="row">
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">NAME</div>
                      <div class="cd-value">{{customer.name}}</div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">GSTIN</div>
                      <div class="cd-value">{{customer.gstin}}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="customer-detail-cont">
                      <div class="cd-title">SHOP NAME</div>
                      <div class="cd-value" matTooltip="Sample Name get the sampple name .pvt">{{customer.shopName}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="customer-detail-cont">
                      <div class="cd-title">MOBILE</div>
                      <div class="cd-value">{{customer.mobile}}</div>
                    </div>
                  </div>
                  <div class="col-md-3 d-flex align-items-center">
                    <div class="customer-status-cont bold"
                      [ngClass]="{'completed': customer.status == 'completed', 'pending': customer.status == 'pending'}">
                      {{customer.status | uppercase}}
                    </div>
                  </div>
                </div>
                <div routerLink='{{customer.gstin}}' class="view-btn d-flex align-items-center justify-content-center">
                  <div>
                    <div>
                      <img src="/assets/icons/arrow-white-right.svg" alt="">
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
            <mat-paginator class="mt-2 w-100 ds" #paginator [length]="customerList.length" [pageSize]="pageSize"
              [pageSizeOptions]="[5, 10, 20]" (page)="onAllPageChanged($event)"></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
