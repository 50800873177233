import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartCountVar = 0;

  constructor() { }

  cartCountSubject = new Subject<any>();
  cartCountObservable = this.cartCountSubject.asObservable()


  cartCount(val){
    // this.cartCountVar = 0
    // this.cartCountVar += val;

    // console.log( JSON.parse(sessionStorage.getItem('cart')).length)
    // this.cartCountSubject.next( JSON.parse(sessionStorage.getItem('cart')).length)
    this.cartCountVar = val
    this.cartCountSubject.next(this.cartCountVar)
    console.log(this.cartCountSubject)
  }
  getCartVal(){
    return this.cartCountVar
  }
}
