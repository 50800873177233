import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.css']
})
export class ProductFilterComponent implements OnInit {
  value: number = 100;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 200
  };
  toppings: FormGroup;
  @Output() isCloseFilter = new EventEmitter<Boolean>();
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {

    this.toppings = this.fb.group({
      pepperoni: false,
      extracheese: false,
      mushroom: false,
    });
  }

  closeFilter() {
    this.isCloseFilter.emit(false);
  }

}
