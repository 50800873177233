<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">WELCOME</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">PROFILE</div>
      <div class="col-md-6 btn-cont">
        <button class="btn btn-cs" [routerLink]="['/']">Contiune Shopping</button>
      </div>
    </div>
  </div>
</div>
<div class="px-g py-5 profile-cont-bg">
  <div class="sr-container">


        <mat-tab-group mat-align-tabs="start">
          <mat-tab>
            <ng-template mat-tab-label>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
              PERSONAL INFORMATION
            </ng-template>
            <div class="col-12 p-4 " *ngIf="profile">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-12 section-sub-heading mb-3">Personal Info</div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>First Name</mat-label>
                      <input formControlName='fname' matInput type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Last Name</mat-label>
                      <input formControlName='lname' matInput type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>E Mail</mat-label>
                      <input formControlName='email' matInput type="email">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Phone Number</mat-label>
                      <input [value]="profile.mobile" disabled matInput type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-12 section-sub-heading mb-3">Shop Info</div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>Shop Name</mat-label>
                      <input matInput type="text" formControlName='shopname'>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="contact-full-width" appearance="fill">
                      <mat-label>GSTIN Number</mat-label>
                      <input disabled [value]="profile.gstin" matInput type="text">
                    </mat-form-field>
                  </div>



                </div>
              </form>

            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/></svg>
              DELIVERY LOCATIONS
            </ng-template>
            <div class="col-12 p-4" *ngIf="profile">
              <!-- <div class="section-sub-heading mb-4">Delivery Address</div> -->
              <div class="row">
                <div class="col-md-3 mb-3 position-relative">
                  <div class="add-new-address" (click)="newAddress(deliveryDialog)">
                    <div class="add-new-icon mb-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="56.833" viewBox="0 0 60 56.833">
                      <g id="add-icon" transform="translate(-409.5 -717.583)">
                        <line id="Line_94" data-name="Line 94" y2="53.833" transform="translate(439.5 719.083)" fill="none" stroke="#ff6631" stroke-linecap="round" stroke-width="3"/>
                        <line id="Line_95" data-name="Line 95" x1="57" transform="translate(411 747.583)" fill="none" stroke="#ff6631" stroke-linecap="round" stroke-width="3"/>
                      </g>
                    </svg></div>
                  </div>
                </div>
                <div class="col-md-3 mb-3 position-relative" *ngFor="let address of profile.address">
                  <div class="delivery-address-cont">
                    <div class="delivery-icon mb-3"><img width="50px" src="/assets/icons/store.svg"></div>
                  <div>{{address.line1}}</div>
                  <div>{{address.line2}}</div>
                  <div>{{address.city}}</div>
                  <div>{{address.state}}</div>
                  <div>{{address.zip}}</div>
                  <hr class="delivery-hr">
                </div>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-end"><button class="btn btn-sr-secondary mt-3"
                  (click)="newAddress()">Add new address</button></div> -->
            </div>
            <!-- <div class="new-address-cont"  *ngIf="showNewAddress">
              <div class="section-sub-heading mb-2">Add New Address</div>
              <form (ngSubmit)="addNewAddress()" [formGroup]="newAddressform">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>Address Line 1</mat-label>
                    <input matInput type="text" formControlName='line1'>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>Address Line 2</mat-label>
                    <input matInput type="text" formControlName='line2'>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput type="text" formControlName='city'>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>State</mat-label>
                    <input matInput type="text" formControlName='state'>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="contact-full-width" appearance="fill">
                    <mat-label>ZIP Code</mat-label>
                    <input matInput type="text" formControlName='zip'>
                  </mat-form-field>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button type="submit" class="btn btn-sr-primary mt-3" >Add</button>
                </div>
              </div>
            </form>
            </div> -->
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z"/></svg>
              BANK DETAILS
            </ng-template>
            <div class="col-12 p-4" *ngIf="profile">
              <!-- <div class="section-sub-heading mb-4">Bank Details</div> -->
              <div class="row">
                <div class="col-md-3 mb-3 position-relative">
                  <div class="add-new-address" (click)="newBankDetails(bankDialog)">
                    <div class="add-new-icon mb-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="56.833" viewBox="0 0 60 56.833">
                      <g id="add-icon" transform="translate(-409.5 -717.583)">
                        <line id="Line_94" data-name="Line 94" y2="53.833" transform="translate(439.5 719.083)" fill="none" stroke="#ff6631" stroke-linecap="round" stroke-width="3"/>
                        <line id="Line_95" data-name="Line 95" x1="57" transform="translate(411 747.583)" fill="none" stroke="#ff6631" stroke-linecap="round" stroke-width="3"/>
                      </g>
                    </svg></div>
                  </div>
                </div>
                <div class="col-md-3 mb-3 position-relative" *ngFor="let bank of profile.bankDetails">
                  <div class="delivery-address-cont">
                    <div class="delivery-icon mb-3"><img width="50px" src="/assets/icons/store.svg"></div>
                  <div>{{bank.bankName}}</div>
                  <div>{{bank.accNo}}</div>
                  <div>{{bank.ifscCode}}</div>
                  <div>{{bank.branchName}}</div>
                  <div>{{bank.accType}}</div>
                  <hr class="delivery-hr">
                </div>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-end"><button class="btn btn-sr-secondary mt-3"
                  (click)="newAddress()">Add new address</button></div> -->
            </div>
          </mat-tab>
        </mat-tab-group>

  </div>

</div>

<ng-template #deliveryDialog>
  <form (ngSubmit)="addNewAddress()" [formGroup]="newAddressform">
  <h2 matDialogTitle>Add New Address</h2>
  <mat-dialog-content>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>Address Line 1</mat-label>
            <input matInput type="text" formControlName='line1'>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>Address Line 2</mat-label>
            <input matInput type="text" formControlName='line2'>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>City</mat-label>
            <input matInput type="text" formControlName='city'>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>State</mat-label>
            <input matInput type="text" formControlName='state'>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contact-full-width" appearance="fill">
            <mat-label>ZIP Code</mat-label>
            <input matInput type="text" formControlName='zip'>
          </mat-form-field>
        </div>
        <!-- <div class="col-12 d-flex justify-content-end">
          <button type="submit" class="btn btn-sr-primary mt-3" >Add</button>
        </div> -->
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="btn mb-3 btn-sr-primary" type="submit">ADD</button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #bankDialog>
    <form (ngSubmit)="addBankDetails()" [formGroup]="bankForm">
  <h2 matDialogTitle>Add Bank Details</h2>
  <mat-dialog-content>
        <!-- <div class="section-sub-heading mb-2">Bank Details</div> -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="contact-full-width" appearance="fill">
              <mat-label>Bank Name</mat-label>
              <input matInput type="text" formControlName='bankName'>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contact-full-width" appearance="fill">
              <mat-label>Account Number</mat-label>
              <input matInput type="text" formControlName='accNo'>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contact-full-width" appearance="fill">
              <mat-label>IFSC Code</mat-label>
              <input matInput type="text" formControlName='ifscCode'>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contact-full-width" appearance="fill">
              <mat-label>Branch Name</mat-label>
              <input matInput type="text" formControlName='branchName'>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contact-full-width" appearance="fill">
              <mat-label>Account Type</mat-label>
              <input matInput type="text" formControlName='accType'>
            </mat-form-field>
          </div>
          <!-- <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-sr-primary mt-3" type="submit">Update</button>
          </div> -->
        </div>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="btn mb-3 btn-sr-primary" type="submit">ADD</button>
    </mat-dialog-actions>
  </form>
</ng-template>

