import { Component, OnInit, ViewChild,TemplateRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PaymentService } from 'src/app/service/payment.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {FormGroup, FormControl} from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';

@Component({
  selector: 'app-admin-payment-list',
  templateUrl: './admin-payment-list.component.html',
  styleUrls: ['./admin-payment-list.component.css']
})
export class AdminPaymentListComponent implements OnInit {
  constructor(private paymentService : PaymentService,  private dialog: MatDialog, public datepipe: DatePipe,private excelService : ExcelGeneratorService){}
  paymentList: any = []
  filterPaymentList: any = []
  displayedColumns: string[]
  dataSource = new MatTableDataSource(this.paymentList);
  date = new Date();
  searchText = '';
  downloadForm = new FormGroup({
    format: new FormControl('excel'),
    start: new FormControl(this.date),
    end: new FormControl(this.date)
  });
// //   @ViewChild(MatSort) set matSort(sort: MatSort) {
// //     if (!this.dataSource.sort) {
// //         this.dataSource.sort = sort;
// //     }
// // }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.paymentService.getPaymentList().subscribe(res=>{
      this.paymentList = res
      this.displayedColumns = Object.keys(this.paymentList[0])
      this.dataSource = new MatTableDataSource(this.paymentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    // this.dataSource.sort = this.sort;
  }
  // onFilter(val){
  //   console.log(val)
  //   if(val =="all"){
  //     this.dataSource = new MatTableDataSource(this.paymentList);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //   }else{
  //     this.filterPaymentList = this.paymentList.filter((payment) => payment.status == val)
  //     this.dataSource = new MatTableDataSource(this.filterPaymentList);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;

  //   }

  // }
  downloadExcel(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '400px',
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  downloadExcelFile(){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `Payment-List-`+latest_date;
    let start_date =this.datepipe.transform(this.downloadForm.value.start, 'yyyy-MM-dd')
    let end_date =this.datepipe.transform(this.downloadForm.value.end, 'yyyy-MM-dd')
    let dateFilter = this.paymentList.filter((payment) => payment.paymentDate >= start_date && payment.paymentDate <= end_date)
    if(this.downloadForm.value.format == 'excel'){
      this.excelService.exportAsExcelFile(dateFilter, fileName);
    }else if(this.downloadForm.value.format == 'pdf'){
      this.excelService.exportAsPdfFile(dateFilter, fileName);
    }
  }
  filterTable(value){
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
