import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/service/spinner.service';
import { SubCategoryService } from 'src/app/service/sub-category.service';

@Component({
  selector: 'app-sub-cat',
  templateUrl: './sub-cat.component.html',
  styleUrls: ['./sub-cat.component.css'],
})
export class SubCatComponent implements OnInit {
  category: any;
  subCat: any;
  subCatList: any;
  tiltSettings: any;
  constructor(
    private subCategoryService: SubCategoryService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      this.route.params.subscribe((params) => {
        this.category = params['cat'];
      });
      this.getSubCategory();
  });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.category = params['cat'];
    });
    this.tiltSettings = { max: 10, speed: 1000, glare: true, maxglare: .000001, scale:1.01 };

    // VanillaTilt.init(document.querySelectorAll(".sub-cat-cont"), { max: 10, speed: 800 });
    // VanillaTilt.init(document.querySelectorAll(".sub-cat-cont"));
    this.getSubCategory();
    console.log(this.category)
  }

  getSubCategory() {
    this.subCategoryService.getSubCategory().subscribe((res) => {
      this.subCat = res;
      this.subCatList = this.subCat.filter(
        (subCat) => subCat.parent == this.category
      )[0];
    });
  }
}
