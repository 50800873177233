import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.css']
})
export class ShimmerComponent implements OnInit {
  @Input('shimmer') shimmer: String;

  constructor() { }

  ngOnInit(): void {
  }

}
