<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">GENERATE</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">REPORT CENTER</div>
    </div>
  </div>
</div>
<div class="px-g cont-bg">
  <div class="sr-container px-0 ">
    <div class="col-12  mt-5 px-0">
      <div class="report-center-cont br mx-auto ds">
        <form [formGroup]="downloadForm" (ngSubmit)="downloadExcelFile()">
          <div class="row">
          <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Format</mat-label>
            <mat-select  formControlName="format" name="format">
              <mat-option value="excel">EXCEL</mat-option>
              <mat-option value="pdf">PDF</mat-option>
            </mat-select>
          </mat-form-field></div><div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Report Type</mat-label>
            <mat-select  formControlName="reportType" name="reportType">
              <mat-option *ngFor="let report of reportType" value="{{report}}">{{report | titlecase}}</mat-option>
            </mat-select>
          </mat-form-field></div><div class="col-12">
          <mat-form-field color="accent"  appearance="fill">
            <mat-label>PO Date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="downloadForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="downloadForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field></div>
        </div>
          <div class="col-12">
            <button type="submit" class="btn mt-3 mx-auto btn-sr-primary" >DOWNLOAD</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
