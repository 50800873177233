import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import { DatePipe } from '@angular/common'
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';
import animShipping from 'src/assets/lottie/shipping.json';
import { AnimationOptions } from 'ngx-lottie';
import { MatStepper } from '@angular/material/stepper';
const pathShipping = `data:text/json;base64,${btoa(JSON.stringify(animShipping))}`;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})

export class OrderComponent implements OnInit {
  orderList : any;
  date = new Date();
  orderSummaryDownload = 'excel';
  optionsShipping: AnimationOptions = {
    path: pathShipping,
    loop: true,
  };
  json= [{
    playerName: 'Cristiano Ronaldo',
    playerCountry: 'Pourtgal',
    playerClub: 'Juventus',
    playerdsadClub: 'Juventus',
    playedsadrClub: 'Juventus',
    playesadasrClub: 'Juventus',
    playedasdrClub: 'Juventus',
    playeasdasrClub: 'Juventus',
    sample: 'Juventus'
  },
  {
    playerName: 'Lionel Messi',
    playerCountry: 'Argentina',
    playerClub: 'Barcelona'
  },
  {
    playerName: 'Neymar Junior',
    playerCountry: 'Brazil',
    playerClub: 'PSG'
  },
  {
  playerName: 'Tonni Kroos',
  playerCountry: 'Germany',
  playerClub: 'Real Madrid'
  },
  {
    playerName: 'Paul Pogba',
    playerCountry: 'France',
    playerClub: 'Manchester United',
    sad: 'Manchester United'
  }]

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('stepper') private myStepper:  MatStepper;

  constructor(private orderService : OrderService, public datepipe: DatePipe,private excelService : ExcelGeneratorService) { }
  ngOnInit(): void {
    this.orderService.getOrder().subscribe(res =>{
      this.orderList = res[0]
    })
  }

  orderSummaryExcel(){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `orderSummary-`+latest_date+`.xlsx`;
    if(this.orderSummaryDownload == 'excel'){
      this.excelService.exportAsExcelFile(this.json, fileName);
    }else if(this.orderSummaryDownload == 'pdf'){
      this.excelService.exportAsPdfFile(this.json, fileName);
    }
  }

  acceptRevisedOrder(){
    this.myStepper.selectedIndex = 2
  }

}
