<ng-container *ngIf="shimmer == 'orderList'">
  <div class="row order-list">
    <div class="col-md-8">
      <div class="col-12" *ngFor="let i of [].constructor(4)">
        <div class="shimmer-cont row row-cols-1 row-cols-sm-2">
          <div class="col shimmerBG orderImg"></div>
          <div class="col p-2">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG title-line end"></div>
            <div class="row mx-0">
              <div class="col input-field shimmerBG"></div>
              <div class="col input-field shimmerBG"></div>
              <div class="col input-field shimmerBG"></div>
              <div class="col input-field shimmerBG"></div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="col-md-4">
      <div class="row">
        <div class="col-12">
          <div class="shimmerBG title-line"></div>
          <div class="shimmerBG title-line end"></div>
          <hr>
        </div>
        <div class="col-12">
          <div class="shimmerBG title-line end"></div>
          <hr>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <hr>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end-2"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end-2"></div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="shimmerBG title-line end"></div>
          <hr>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end"></div>
            </div>
          </div>
          <hr>
          <div class="row justify-content-between">
            <div class="col">
              <div class="shimmerBG title-line end-2"></div>
            </div>
            <div class="col d-flex justify-content-end">
              <div class="shimmerBG title-line end-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="shimmer == 'orderPayment'">
  <div class="col-12 px-0">
    <div class="shimmerBG title-line end-70"></div>
    <div class="shimmerBG title-line end"></div>
    <hr>
  </div>
  <div class="row d-flex justify-content-between">
    <div class="col-12">
      <div class="shimmerBG title-line end-10"></div>
      <div class="shimmerBG title-line end-50"></div>
    </div>
  <div class="col-md-4">
    <div class="row">

      <div class="col-12 mt-3">
        <div class="row justify-content-between">
          <div class="col">
            <div class="shimmerBG title-line end"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="shimmerBG title-line end"></div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col">
            <div class="shimmerBG title-line end"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="shimmerBG title-line end"></div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col">
            <div class="shimmerBG title-line end"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="shimmerBG title-line end"></div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col">
            <div class="shimmerBG title-line end"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="shimmerBG title-line end"></div>
          </div>
        </div>
        <hr>
        <div class="row justify-content-between">
          <div class="col">
            <div class="shimmerBG title-line end-2"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="shimmerBG title-line end-2"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-md-4">
    <div class="shimmerBG title-line end ml-auto"></div>
    <hr>
    <div class="row justify-content-between">
      <div class="col">
        <div class="shimmerBG title-line end"></div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="shimmerBG title-line end"></div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="shimmerBG title-line end"></div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="shimmerBG title-line end"></div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="shimmerBG title-line end"></div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="shimmerBG title-line end"></div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="shimmerBG title-line end"></div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="shimmerBG title-line end"></div>
      </div>
    </div>
    <hr>
    <div class="row justify-content-between">
      <div class="col">
        <div class="shimmerBG title-line end-2"></div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="shimmerBG title-line end-2"></div>
      </div>
    </div>
  </div>
</div>
</ng-container>

<ng-container *ngIf="shimmer == 'productList'">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 ">
    <div class="col" *ngFor="let i of [].constructor(4)">
      <div class="shimmer-cont">
        <div class="shimmerBG media"></div>
        <div class="p-2">
          <div class="shimmerBG title-line"></div>
          <div class="shimmerBG title-line end"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
