import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() { }

  isSpinnerOn : Boolean = false
  isLoaderOn : Boolean = false
  isSuccessOn : Boolean = false

  private spinnerSubject = new Subject<any>();
  private loaderSubject = new Subject<any>();
  private successSubject = new Subject<any>();

  spinnerObservable = this.spinnerSubject.asObservable()
  loaderObservable = this.loaderSubject.asObservable()
  successObservable = this.successSubject.asObservable()

  spinner(val : Boolean){
    this.spinnerSubject.next(val)
  }
  loader(val : Boolean){
    this.loaderSubject.next(val)
  }
  success(val : Boolean, message?){
    this.successSubject.next({status: val,message: message})
  }
}
