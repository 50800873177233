<div class="heading-cont  px-g d-flex align-items-center">
  <div class="sr-container position-relative">
    <div class="title-overlay">CHECKOUT</div>
    <div class="row">
      <div class="col-md-6 section-heading cat-heading">CART</div>
      <div class="col-md-6 btn-cont">
        <button class="btn btn-cs" [routerLink]="['/']">Contiune Shopping</button>
      </div>
    </div>
  </div>
</div>
<div class="px-g px-0 py-5 cart-cont">
  <div class="sr-container">
    <div class="row">
      <div class="col-lg-8">
        <div *ngIf="cart.length > 0; else emptyCart">

          <form [formGroup]="cartForm">
            <div formArrayName="items">
              <div class="col-12 cart-item-cont br ds mb-1 overflow-hidden" *ngFor="let cartItems of items().controls; let i=index">
                <div class="row" [formGroupName]="i">
                  <div class="col-md-4">
                    <div><img width="100%" [src]="cart[i].imgPath[0]" alt=""></div>
                  </div>
                  <div class="col-md-8">
                    <div class="row h-100">
                      <div class="col-sm-8 py-3">
                        <div class="item-id mb-1">ID: {{cart[i].id}}</div>
                        <div class="item-name mb-3">{{cart[i].name}}</div>
                        <div>
                          <div formArrayName="quantity" class="input-parent-cont">
                            <div *ngFor="let quan of quantity(i).controls; let quanIndex = index">
                              <div class="d-flex flex-column" *ngIf="cart[i].items[quanIndex].stock != 0" [ngClass]="{'active-cart': cart[i].quantity[quanIndex].quantity> 0}">
                                <div [formGroupName]="quanIndex" class="input-cont">
                                  <div class="input-label"><label>{{cart[i].quantity[quanIndex].size}}</label></div>
                                  <div class="input-size">
                                    <input placeholder="" min="0" max="{{cart[i].quantity[quanIndex].stock}}"
                                      type="number" formControlName="quantity" />
                                  </div>
                                </div>
                              </div>
                              <div class="stock-cont" *ngIf="cartForm.invalid && cart[i].items[quanIndex].stock>0">
                                {{cart[i].items[quanIndex].stock}}</div>

                            </div>
                          </div>
                          <div class="mt-3" *ngIf="cartForm.invalid">Available Stocks</div>
                        </div>
                      </div>
                      <div class="sub-item-cont col-sm-4 d-flex align-items-end justify-content-between">
                        <div class="">
                          <button class="btn btn-delete-cart" (click)="deleteFromCart(i,cart[i].id)"><img width="21px"
                              src="/assets/icons/delete-white.svg"></button>
                        </div>
                        <div class="item-price">₹ {{cart[i].totalPrice}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right">
              <button class="my-3 btn btn-sr-secondary" [disabled]="cartForm.invalid" (click)="updateCart()">Update
                Cart</button>
            </div>
          </form>


        </div>
        <ng-template #emptyCart>
          <div class="empty-cart-cont mb-5 br">
            <ng-lottie  height="220px" [options]="optionsLoader"></ng-lottie>
            <div class="text-center my-3">
              Your cart is empty!
            </div>
            <div class="col-12 justify-content-center btn-cont">
              <button class="btn btn-cs" [routerLink]="['/']">Contiune Shopping</button>
            </div>
          </div>
        </ng-template>

      </div>
      <div class="col-lg-4 mb-3">
        <div class="price-details-cont br ds">
          <div class="price-title">PRICE DETAILS</div>
          <hr class="price-hr">
          <div>
            <div class="row mb-2">
              <div class="col-6 pd-title">Actual Price</div>
              <div class="col-6 text-right pd-value">₹ {{cartPrice}}</div>
            </div>
            <div class="row mb-2">
              <div class="col-6 pd-title">Discount</div>
              <div class="col-6 text-success text-right pd-value">- ₹ 0</div>
            </div>
            <div class="row mb-2">
              <div class="col-6 pd-title">Tax</div>
              <div class="col-6 text-right pd-value">₹ {{cartPrice}}</div>
            </div>
          </div>
          <hr class="price-hr">
          <div class="row align-items-end">
            <div class="col-6 mb-2 total-price-title">Total Price</div>
            <div class="col-6 text-right total-price">₹ {{cartPrice}}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-8" *ngIf="shippingDetails">
        <div class="shipping-title">SHIPPING DETAILS</div>
        <div class="shipping-cont br ds">
          <div class="row">
            <!-- <div class="col-md-6">
              <mat-form-field class="contact-full-width" appearance="fill">
                <mat-label>Name</mat-label>
                <input value="{{shippingDetails.fname}} {{shippingDetails.lname}}" matInput type="text">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contact-full-width" appearance="fill">
                <mat-label>Mobile</mat-label>
                <input value="{{shippingDetails.mobile}}" matInput type="number">
              </mat-form-field>
            </div> -->
            <div class="col-12">
              <div class="mb-2">Address</div>
              <mat-radio-group aria-label="Select an option">
                <div class="row  row-cols-1 row-cols-md-2">
                  <div class="col px-2" *ngFor="let address of shippingDetails.address; let i = index">
                    <mat-radio-button value="{{i}}" class="text-wrap"><div class="ml-2 text-wrap">{{address.line1}}, {{address.line2}}, {{address.city}},
                      {{address.state}}, {{address.zip}}.</div></mat-radio-button>
                    </div>
                  </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="mt-3 btn btn-sr-primary" [disabled]="cartForm.invalid || cart.length == 0" (click)="placeOrder()">PLACE ORDER</button>
        </div>
      </div>
    </div>
  </div>
</div>
