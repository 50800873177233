import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { GraphService } from 'src/app/service/graph.service';
/** Admin Dashboard Component */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  /**Varaiable to hold sales chart */
  mainChart: Chart;
  /**Varaiable to hold sales graph data*/
  graphData : any;
  /**Varaiable to hold sales graph data for specific year*/
  salesGraphData : any;
  /**Varaiable to hold sales graph year*/
  salesGraphYear : any = [];
  /**
   * Constructor
   * @param graphService Service for getting Graphs Values/Data from API
   */
  constructor(private graphService: GraphService) { }

  ngOnInit(): void {
    this.graphService.getGraphData().subscribe(res=>{
      this.graphData = res
      this.salesGraphData = this.graphData.filter(graph => graph.graph == "salesGraph")[0].data
      this.salesGraphData["year"] = this.salesGraphData[0].year
      let salesData = []
      for (const iterator of this.salesGraphData) {
        this.salesGraphYear.push(iterator.year)
      }
      for (const iterator of this.salesGraphData[0].data) {
        salesData.push(iterator.value)
      }
      this.salesGraphData["data"] = salesData
      this.createSalesGraph()
      console.log(this.salesGraphData)
    })


  }
  /**Functions to create Sales graph */
  createSalesGraph(){
    this.mainChart = new Chart('mainChart', {
      type: 'line',
      plugins: [{
        afterLayout: c => {
          let dataset = c.data.datasets[0];

          let gradientFill = c.ctx.createLinearGradient(0, 0, 0, 400);
          gradientFill.addColorStop(0, 'rgba(55,67,102,1)');
          gradientFill.addColorStop(1, 'rgba(55,67,102,0)');
          dataset.backgroundColor = gradientFill;
        }
      }],
      data: {
        labels: ['JAN', 'FEB', 'MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
        datasets: [
          {
            lineTension: 0,
            label: 'SALES',
            data: this.salesGraphData["data"],
            backgroundColor: "#374366",
            borderColor: "#ffffff85",
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: '#FFFFFF',
            pointBorderWidth: 2,
            pointHoverBorderColor: 'rgba(255, 255, 255, 0.2)',
            pointHoverBorderWidth: 10,
            fill: true,
          }
        ]
      },
      options: {
        elements: {
        	point: {
            radius: 4,
          hitRadius: 6,
          hoverRadius: 6
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: 'Color test'
        },
        legend: {
          position: 'top',
          display: false,
          fullWidth: true,
          labels: {
            fontSize: 11
          }
        },
        tooltips: {
          backgroundColor: 'transparent',
          displayColors: false,
          bodyFontSize: 14,
          position:'average'
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: "#ffffff95"
          }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true,
              color: "#ffffff15"
            },
            ticks: {
              beginAtZero: true,
              fontColor: "#ffffff25",
              padding:10

          }
          }]
        }
      }
    });
  }
  /**Fuctions to update the value of Sales graphs when year changed by user*/
  changeSalesYear(val){
    let salesData = []
    for (const iterator of this.salesGraphData.filter(data => data.year == val)[0].data) {
      salesData.push(iterator.value)
    }
    this.mainChart.data.datasets[0].data = salesData
    this.mainChart.update();
  }
}
