import { Component, OnInit ,TemplateRef} from '@angular/core';
import { ProfileService } from 'src/app/service/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profile: any;
  bankDetails: any;
  form: FormGroup;
  newAddressform: FormGroup;
  showNewAddress = false;
  bankForm : FormGroup;

  constructor(private profileService: ProfileService,private dialog: MatDialog,) {}
  ngOnInit(): void {
    this.newAddressform = new FormGroup({
      line1: new FormControl(null, { validators: [Validators.required] }),
      line2: new FormControl(null, { validators: [Validators.required] }),
      city: new FormControl(null, { validators: [Validators.required] }),
      state: new FormControl(null, { validators: [Validators.required] }),
      zip: new FormControl(null, { validators: [Validators.required] })
    });
    this.bankForm = new FormGroup({
      bankName: new FormControl(null, { validators: [Validators.required] }),
      accNo: new FormControl(null, { validators: [Validators.required] }),
      ifscCode: new FormControl(null, { validators: [Validators.required] }),
      branchName: new FormControl(null, { validators: [Validators.required] }),
      accType: new FormControl(null, { validators: [Validators.required] })
    })
    this.getProfile()

  }

  getProfile() {
    this.profileService.getProfile().subscribe(
      (res) => {
        this.profile = res[0];
        console.log(this.profile)
        this.form = new FormGroup({
          fname: new FormControl(this.profile.fname, { validators: [Validators.required] }),
          lname: new FormControl(this.profile.lname, { validators: [Validators.required] }),
          email: new FormControl(this.profile.email, { validators: [Validators.required] }),
          shopname: new FormControl(this.profile.shopname, { validators: [Validators.required] }),
          // gstin: new FormControl(this.profile.gstin, { validators: [Validators.required] }),
          // mobile: new FormControl(this.profile.mobile, { validators: [Validators.required]}),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addNewAddress(){
    if(this.newAddressform.valid){
      console.log(this.newAddressform.value)
      this.profile.address.push(this.newAddressform.value)
      this.newAddressform.reset()
      this.dialog.closeAll();
    }
  }
  newAddress(templateRef: TemplateRef<any>){
    this.dialog.open(templateRef, {
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy()
    });
    // this.showNewAddress = true
  }
  newBankDetails(templateRef: TemplateRef<any>){
    this.dialog.open(templateRef, {
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  addBankDetails(){
    if(this.bankForm.valid){
      this.profile.bankDetails.push(this.bankForm.value)
      this.bankForm.reset()
      this.dialog.closeAll();
    }
  }
}
