import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { OrderService } from 'src/app/service/order.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})

export class OrderListComponent implements OnInit {
  constructor(private orderService : OrderService, public datepipe: DatePipe,private excelService : ExcelGeneratorService,  private dialog: MatDialog){}
  orderList: any = []
  filterOrderList: any = []
  displayedColumns: string[]
  dataSource = new MatTableDataSource(this.orderList);
  date = new Date();
  status = ['all','pending','revised','approved','shipped','completed','rejected']
  downloadForm = new FormGroup({
    format: new FormControl('excel'),
    status: new FormControl('all'),
    start: new FormControl(this.date),
    end: new FormControl(this.date)
  });

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.orderService.getOrderList().subscribe(res=>{
      this.orderList = res
      this.displayedColumns = Object.keys(this.orderList[0])
      this.dataSource = new MatTableDataSource(this.orderList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    // this.dataSource.sort = this.sort;
  }
  onFilter(val){
    console.log(val)
    if(val =="all"){
      this.dataSource = new MatTableDataSource(this.orderList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }else{
      this.filterOrderList = this.orderList.filter((order) => order.status == val)
      this.dataSource = new MatTableDataSource(this.filterOrderList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    }

  }

  downloadExcel(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '400px',
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  downloadExcelFile(){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `Payment-List-`+latest_date;
    // let downloadList = this.orderList

    let start_date =this.datepipe.transform(this.downloadForm.value.start, 'yyyy-MM-dd')
    let end_date =this.datepipe.transform(this.downloadForm.value.end, 'yyyy-MM-dd')
    let downloadList = this.orderList.filter((order) => order.poDate >= start_date && order.poDate <= end_date)

    if(this.downloadForm.value.status !== "all"){
      downloadList = downloadList.filter((order) => order.status == this.downloadForm.value.status)
    }
    if(this.downloadForm.value.format == 'excel'){
      this.excelService.exportAsExcelFile(downloadList, fileName);
    }else if(this.downloadForm.value.format == 'pdf'){
      this.excelService.exportAsPdfFile(downloadList, fileName);
    }
  }

}
