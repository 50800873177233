import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/service/profile.service';
import { FormGroup,  FormArray, Validators,  FormBuilder} from '@angular/forms';
import { ProductService } from 'src/app/service/product.service';
import { CartService } from 'src/app/service/cart.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import animEmptycart from 'src/assets/lottie/empty-cart.json';
import { AnimationOptions } from 'ngx-lottie';
const pathEmptycart = `data:text/json;base64,${btoa(
  JSON.stringify(animEmptycart)
)}`;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  products: any;
  cart = [];
  cartSession = [];
  cartForm: FormGroup;
  cartPrice = 0;
  shippingDetails : any;
  optionsLoader: AnimationOptions = {
    path: pathEmptycart,
    loop: true,
  };
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private profileService: ProfileService,
    private spinnerService: SpinnerService,
    private cartService : CartService
  ) {}

  ngOnInit(): void {
    this.cart = [];
    this.cartSession = [];
    if (sessionStorage.getItem('cart')) {
      this.cartSession = JSON.parse(sessionStorage.getItem('cart'));
    }
    this.getProducts();
    this.getShippingDetails()
  }

  getProducts() {
    this.productService.getProduct().subscribe(
      (res) => {
        this.products = res;
        this.products.forEach((product) => {
          let priceRange = [];
          product.items.forEach((item) => {
            priceRange.push(item.price);
          });
          product['price'] = {
            from: Math.min.apply(null, priceRange),
            to: Math.max.apply(null, priceRange),
          };
        });
        this.assignCart();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  assignCart() {
    this.cartSession.forEach((cart) => {
      let cartItems = this.products.filter(
        (product) => product.id == cart.id
      )[0];
      cartItems['quantity'] = cart.quantity;
      this.cart.push(cartItems);
    });
    this.cart.forEach(cart=>{
      cart.quantity.forEach(quantity => {
        cart.items.forEach(item => {
          if(item.size == quantity.size){
            quantity['stock'] = item.stock
          }
        });
      });
    })
    console.log(this.cart)
    this.createForm();
    this.updatePrice()
    this.cartService.cartCount(this.cart.length)

  }
  createForm() {
    this.cartForm = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
    let index = 0;
    this.cart.forEach((product) => {
      this.items().push(
        this.formBuilder.group({
          id: [product.id, Validators.required],
          quantity: this.formBuilder.array([]),
        })
      );
      product.quantity.forEach((quantity) => {
        this.quantity(index).push(
          this.formBuilder.group({
            size: [quantity.size, Validators.required],
            quantity: [
              quantity.quantity,
              {
                validators: [
                  Validators.required,
                  Validators.min(0),
                  Validators.max(quantity.stock),
                ],
              },
            ],
          })
        );
      });
      index += 1;
    });
  }
  deleteFromCart(i,id) {
    this.cart = this.cart.filter(item => item.id !== id);
    this.cartSession = this.cartSession.filter(item => item.id !== id);
    sessionStorage.setItem('cart', JSON.stringify(this.cartSession));
    this.items().removeAt(i);
    this.updatePrice()
    this.cartService.cartCount(this.cart.length)

  }
  updateCart(){
    this.cartSession= this.cartForm.value.items
    this.cart.forEach(cart=>{
      this.cartSession.forEach(cartSession => {
        if(cart.id == cartSession.id){
          cart['quantity']= cartSession.quantity
        }
      });
    })

    this.updatePrice()
    sessionStorage.setItem('cart', JSON.stringify(this.cartSession));
  }
  updatePrice(){
    this.cartPrice = 0
    this.cart.forEach(cartItems => {
      let totalPrice = 0
      cartItems.quantity.forEach(quantity => {
        let cart = cartItems.items.filter(item => item.size === quantity.size)[0];
        let cartQuantity = cartItems.quantity.filter(item => item.size === quantity.size)[0];
        quantity['price'] = cart.price
        totalPrice += (cart.price*cartQuantity.quantity)
      });
      cartItems["totalPrice"] = totalPrice
      this.cartPrice += totalPrice
    });

  }
  getShippingDetails(){
    this.profileService.getProfile().subscribe(res=>{
      this.shippingDetails = res[0]
    })
  }

  items(): FormArray {
    return this.cartForm.get('items') as FormArray;
  }
  quantity(id): FormArray {
    return this.items().at(id).get('quantity') as FormArray;
  }
  placeOrder(){
    this.spinnerService.success(true, 'You Order has been Placed successfully')
    setTimeout(()=>{
      this.spinnerService.success(false) // Timeout has been added insited of Res time
  }, 5000);
  }
}
