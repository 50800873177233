import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  url = environment.baseURL;

  constructor(private http: HttpClient) {}

  getProduct() {
    return this.http.get(this.url + '/products.json');
  }
  addProduct(product, img){
    const postData = new FormData();
    postData.append("name",product.name)
    postData.append("description",product.description)
    postData.append("discount",product.discount)
    postData.append("category",product.category)
    postData.append("items",product.items)
    postData.append("specification",product.specification)
    postData.append("subcategory",product.subcategory)
    postData.append("image",img)
    console.log(postData)

  }
}
