import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoggedIn = false;
  constructor() { }

  ngOnInit(): void {
  }
  onLogin(){
    this.isLoggedIn = true
  }
  clickEvent(first,last){
    if((<HTMLInputElement>document.getElementById(first)).value.length){
      document.getElementById(last).focus();
    }
  }
}
