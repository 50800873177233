import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { ExcelGeneratorService } from 'src/app/service/excel-generator.service';
import { InventoryService } from 'src/app/service/inventory.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  inventoryList: any = []
  filterinventoryList: any = []
  displayedColumns = ['imgPath', 'name','id','category','subcategory']
  status = ['all','pending','revised','approved','shipped','completed','rejected']
  dataSource = new MatTableDataSource(this.inventoryList);
  searchText = '';
  date = new Date();
  downloadForm = new FormGroup({
    format: new FormControl('excel'),
    status: new FormControl('all'),
    start: new FormControl(this.date),
    end: new FormControl(this.date)
  });

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private dialog: MatDialog, public datepipe: DatePipe,private excelService : ExcelGeneratorService, private inventoryService : InventoryService) { }



  ngOnInit(): void {
    this.inventoryService.getInventoryList().subscribe(res=>{
      this.inventoryList = res
      // this.displayedColumns = Object.keys(this.inventoryList[0])
      this.dataSource = new MatTableDataSource(this.inventoryList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }


  updateInventoryRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }


  onFilter(val){
    console.log(val)
    if(val =="all"){
      this.dataSource = new MatTableDataSource(this.inventoryList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }else{
      this.filterinventoryList = this.inventoryList.filter((inventory) => inventory.status == val)
      this.dataSource = new MatTableDataSource(this.filterinventoryList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    }

  }
  downloadExcel(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      maxWidth: '400px',
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  downloadExcelFile(){
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let fileName= `Payment-List-`+latest_date;
    // let downloadList = this.inventoryList

    let start_date =this.datepipe.transform(this.downloadForm.value.start, 'yyyy-MM-dd')
    let end_date =this.datepipe.transform(this.downloadForm.value.end, 'yyyy-MM-dd')
    let downloadList = this.inventoryList.filter((inventory) => inventory.poDate >= start_date && inventory.poDate <= end_date)

    if(this.downloadForm.value.status !== "all"){
      downloadList = downloadList.filter((inventory) => inventory.status == this.downloadForm.value.status)
    }
    if(this.downloadForm.value.format == 'excel'){
      this.excelService.exportAsExcelFile(downloadList, fileName);
    }else if(this.downloadForm.value.format == 'pdf'){
      this.excelService.exportAsPdfFile(downloadList, fileName);
    }
  }
  filterTable(value){
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
