<nav class="navbar navbar-dark px-g">
  <div class="sr-container container px-0">
    <div>
    <a class="navbar-brand d-flex align-items-center" [routerLink]="['/']">
      <div>
        <img height="30px" src="/assets/images/logo.png">
      </div>
      <div class="ml-2">DSP TRENDS</div>
    </a>
  </div>
    <div class="menu-opened" [ngClass]="{ 'active' : isMenuOpened }">
      <div></div>
      <div class="nav-link menu-items">
        <ul class="menu">
          <li class="menu-li" *ngFor="let menu of menuList; let index = index">
            <!-- First Tier Drop Down -->
            <label for="drop-{{index}}" class="toggle">{{menu.parent}}</label>
            <a (click)="closeMenu()" routerLink='/product/{{menu.parent}}'>{{menu.parent}}</a>
            <div (click)="subMenu()" class="mob-nav-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="36"
                height="36" viewBox="0 0 36 36">
                <g id="orange-arrow" transform="translate(-786 -30)">
                  <circle id="Ellipse_43" data-name="Ellipse 43" cx="18" cy="18" r="18"
                    transform="translate(822 66) rotate(180)" fill="#ff6631" />
                  <g id="Group_225" data-name="Group 225" transform="translate(1327 560) rotate(180)">
                    <line id="Line_59" data-name="Line 59" x2="6" y2="6" transform="translate(519.5 512.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="3" />
                    <line id="Line_60" data-name="Line 60" x1="6" y2="6" transform="translate(519.5 506.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="3" />
                  </g>
                </g>
              </svg></div>
            <hr class="menu-hr">
            <input type="checkbox" id="drop-{{index}}" />
            <ul class="inner-nav inner-nav-mob" [ngClass]="{ 'active' : isSubMenuOpened }">
              <div class="inner-nav-back" (click)="closeSubMenu()">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <g id="orange-arrow-back" transform="translate(822 66) rotate(180)">
                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="18" cy="18" r="18"
                      transform="translate(822 66) rotate(180)" fill="#ff6631" />
                    <g id="Group_225" data-name="Group 225" transform="translate(1327 560) rotate(180)">
                      <line id="Line_59" data-name="Line 59" x2="6" y2="6" transform="translate(519.5 512.5)"
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
                      <line id="Line_60" data-name="Line 60" x1="6" y2="6" transform="translate(519.5 506.5)"
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
                    </g>
                  </g>
                </svg>
                <div>Back</div>
              </div>
              <li *ngFor="let subCat of menu.subcatergory">
                <a (click)="closeMenu()" routerLink='/product/{{menu.parent}}/{{subCat.path}}'>{{subCat.name}}</a>
                <hr class="menu-hr-li">
              </li>

            </ul>
          </li>
        </ul>
      </div>
      <div class="nav-link menu-icons">
        <!-- <div class="mr-4" [routerLink]="['/products']">Products</div> -->


        <div class="mr-4 cart-cont d-flex align-items-center icon-cont" [routerLink]="['/cart']">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
            width="24px" fill="#FFFFFF">
            <g>
              <rect fill="none" height="24" width="24" />
              <path
                d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" />
            </g>
          </svg>
          <div class="cart-count">{{cartCountVar}}</div>
        </div>
        <div class="mr-4 d-flex align-items-center icon-cont" [routerLink]="['/profile']">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
          </svg> -->

          <svg  style="width: 25px;height:25px " xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z"/></svg>
        </div>

        <div class="mr-4 d-flex align-items-center icon-cont" [routerLink]="['/orders']">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
            width="24px" fill="#FFFFFF">
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g>
                <path
                  d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z" />
                <rect height="2" width="6" x="9" y="12" />
              </g>
            </g>
          </svg>
        </div>
        <div class="mr-4 d-flex align-items-center icon-cont" [routerLink]="['/login']">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
            width="24px" fill="#FFFFFF">
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
              <path
                d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
            </g>
          </svg>
        </div>
        <!-- <ul class="menu more-menu">
          <li class="menu-li">
            <label for="dropm-1" class="toggle">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
              </svg>
            </label>
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
              </svg>

            </a>
            <input type="checkbox" id="dropm-1" />
            <ul class="inner-nav">
              <li>
                <a routerLink='/profile'>
                  <div class="d-flex">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#FFFFFF">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
                      </svg>
                    </div>
                    <div class="ml-3">PROFILE</div>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink='/orders'>
                  <div class="d-flex">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                        viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                        <g>
                          <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                          <g>
                            <path
                              d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z" />
                            <rect height="2" width="6" x="9" y="12" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="ml-3">MY ORDERS</div>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink='/login'>
                  <div class="d-flex">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                        viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                        <g>
                          <path d="M0,0h24v24H0V0z" fill="none" />
                        </g>
                        <g>
                          <path
                            d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
                        </g>
                      </svg>
                    </div>
                    <div class="ml-3">
                      LOG OUT
                    </div>
                  </div>
                </a>
              </li>

            </ul>
          </li>
        </ul> -->
      </div>
    </div>
    <div class="menu-overlay" [ngClass]="{ 'active' : isMenuOpened }" (click)="openMenu()"></div>
    <div class="menu-overlay2" [ngClass]="{ 'active' : isMenuOpened }" (click)="openMenu()"></div>
    <div class="menubtn" [ngClass]="{ 'open' : isMenuOpened }" (click)="openMenu()">
      <div class="line"></div>
    </div>
  </div>
</nav>
