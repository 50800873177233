<div class="banner">
  <carousel [interval]="myInterval" [(activeSlide)]="activeSlideIndex" >
    <slide *ngFor="let slide of banner; let index=index">
      <img [src]="slide.imagePath" alt="image slide" style="display: block;">
    </slide>
  </carousel>
  <!-- <img src="/assets/images/banner1.png" width="100%" alt=""> -->
</div>
<div class="category-cont mb-5">
  <div class="sr-container">
    <div class="row">
      <div class="col-12 my-5">
        <div class="section-heading heading text-center">category</div>
        <hr class="sr-hr">
      </div>
      <div class="col-md-4 mb-4">
        <div class="cat-cont" data-tilt data-tilt-glare data-tilt-max-glare="0.3" data-tilt-scale="1.02"
        [routerLink]="['/product/boys']">
          <div><hr class="cat-hr"></div>
          <div class="top-cat-cont">
            <div class="section-heading cat-text">BOYS</div>
          </div>
          <div class="img-cont">
            <img width="100%" src="/assets/images/cat-boys.jpg" alt="">
          </div>
          <div class="cat-btn">
            <button class="btn btn-sr-cat-primary" >
              <div>VIEW PRODUCTS</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="cat-cont" data-tilt data-tilt-glare data-tilt-max-glare="0.3" data-tilt-scale="1.02"
        [routerLink]="['/product/girls']">
          <div><hr class="cat-hr"></div>
          <div class="top-cat-cont">
            <div class="section-heading cat-text">GIRLS</div>
          </div>
          <div class="img-cont">
            <img width="100%" src="/assets/images/cat-girls.jpg" alt="">
          </div>
          <div class="cat-btn">
            <button class="btn btn-sr-cat-primary">
              <div>VIEW PRODUCTS</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="cat-cont" data-tilt data-tilt-glare data-tilt-max-glare="0.3" data-tilt-scale="1.02"
        [routerLink]="['/product/women']">
          <div><hr class="cat-hr"></div>
          <div class="top-cat-cont">
            <div class="section-heading cat-text">WOMEN</div>
          </div>
          <div class="img-cont">
            <img width="100%" src="/assets/images/cat-women.jpg" alt="">
          </div>
          <div class="cat-btn">
            <button class="btn btn-sr-cat-primary">
              <div>VIEW PRODUCTS</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="cat-cont" data-tilt data-tilt-glare data-tilt-max-glare="0.3" data-tilt-scale="1.02"
        [routerLink]="['/product/men']">
          <div><hr class="cat-hr"></div>
          <div class="top-cat-cont">
            <div class="section-heading cat-text">MEN</div>
          </div>
          <div class="img-cont">
            <img width="100%" src="/assets/images/cat-men.jpg" alt="">
          </div>
          <div class="cat-btn">
            <button class="btn btn-sr-cat-primary">
              <div>VIEW PRODUCTS</div>
              <div class="pl-3 d-flex icon"><img src="/assets/icons/arrow-white-right.svg"></div>
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
